import React from 'react'
import './AboutUS.css'

const AboutUSBG = () => {
  return (
    <div>
      <div className=' backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background md:p-12 p-3 '>
      <div className=' font-mon not-italic font-bold text-4xl text-white pt-12'>
        <p>About Us</p>
      </div>
    </div>
    </div>
  )
}

export default AboutUSBG
