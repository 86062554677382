const AccordionGQObject =
[
    {
        id: 1,
        header: "What is The Brands Bucks and what do you offer?",
        body: "The Brands Bucks is committed to providing quality reviews for brands and products. We offer a platform for dedicated reviewers to share valuable feedback on various products.",

    },
    {
        id: 2,
        header: "Why do you have a security deposit policy for new reviewers?",
        body: "We have implemented a security deposit policy for new reviewers from certain regions to maintain the integrity of our reviewing program. This policy helps mitigate the risk of individuals exploiting the program for personal gain without fulfilling their reviewing responsibilities.",

    },
    {
        id: 3,
        header: "What are the reviewer categories and deposit amounts?",
        body: "We have three distinct reviewer categories: Silver, Gold, and Platinum, each requiring a specific deposit amount. The deposit serves as a testament to our reviewers' commitment to the reviewing process.",

    },
    {
        id: 4,
        header: "How does the deposit system work?",
        body: "Upon joining our reviewing program, the deposit will be held for a duration of three months. If a reviewer completes a minimum of two tasks and decides to discontinue their participation, they may request a refund of the deposit at the conclusion of the three-month period.",

    },
    {
        id: 5,
        header: "Why do refunds only occur at the end of the three-month period?",
        body: "Refunds are processed at the conclusion of the three-month period to ascertain the seriousness and commitment of our reviewers. This ensures mutual respect and accountability in our partnership.",

    },
    {
        id: 6,
        header: "How does this policy benefit both reviewers and The Brands Bucks?",
        body: "This policy fosters a transparent and mutually beneficial relationship between reviewers and our company. It helps us deliver authentic and insightful reviews that benefit both consumers and brands.",

    },
    {
        id: 7,
        header: "How can I join The Brands Bucks reviewing program?",
        body: "You can join our reviewing program by signing up on our website and following the application process. We look forward to collaborating with dedicated reviewers who share our passion for excellence.",

    },
    {
        id: 8,
        header: "How do you ensure the quality and credibility of your brand and product reviews?",
        body: "Our seasoned team of expert reviewers undergoes a meticulous evaluation process to deliver comprehensive insights. We prioritize accuracy, relevance, and objectivity in every review, ensuring consumers receive trustworthy guidance.",

    },
    {
        id: 9,
        header: "What sets The Brand Bucks apart from other review platforms?",
        body: "At The Brand Bucks, we pride ourselves on our commitment to excellence. Our reviews are conducted by industry experts who provide in-depth analysis and valuable insights, empowering consumers to make informed decisions.",

    },
    {
        id: 10,
        header: "How do brands benefit from being reviewed on your platform?",
        body: "Brands featured on our platform experience heightened visibility, enhanced credibility, and increased revenue streams. Our genuine feedback serves as a catalyst for accelerated growth, enabling brands to connect with consumers on a deeper level.",

    },
    {
        id: 11,
        header: "How are your reviewers compensated for their contributions?",
        body: "Our esteemed reviewers are duly compensated for their invaluable contributions to the review process. We believe in fostering a symbiotic relationship built on mutual benefit and professionalism.",

    },
    {
        id: 12,
        header: "How can brands and products be featured on The Brand Bucks?",
        body: "Brands interested in being reviewed on our platform can reach out to us through our website. Our team will evaluate the suitability of the brand or product for review and provide further instructions on the process.",

    },
    {
        id: 13,
        header: "Do you accept sponsored reviews or paid placements?",
        body: "We maintain strict editorial integrity and do not accept sponsored reviews or paid placements. Our reviews are based solely on the merit of the brand or product, ensuring unbiased and impartial assessments.",

    },
    {
        id: 14,
        header: "How can consumers trust the reviews on The Brand Bucks?",
        body: "We uphold the highest standards of transparency, integrity, and professionalism in our reviewing process. Our commitment to delivering insightful and impactful reviews is unwavering, earning the trust of consumers seeking reliable guidance in their purchasing decisions.",

    },
    {
        id: 15,
        header: "What is The Brand Bucks?",
        body: "The Brand Bucks is a company dedicated to transforming businesses through comprehensive Business Process Outsourcing (BPO) and Business Development Services (BDS), along with IT Development and Marketing and Advertising services.",

    },
    {
        id: 16,
        header: "How can The Brand Bucks help my business?",
        body: "We provide tailored solutions to enhance operational efficiency, reduce costs, and support strategic growth, allowing you to focus on your core business activities.",

    },
    {
        id: 17,
        header: "What industries do you serve?",
        body: "We serve a wide range of industries including Retail and E-commerce, Healthcare, Finance and Banking, Telecommunications, Technology, Travel and Hospitality, Manufacturing, Education, Real Estate, Logistics and Transportation, Media and Entertainment, Legal Services, Non-Profit Organizations, Energy and Utilities, Agriculture, Automotive, Pharmaceuticals, Construction, Consumer Goods, and the Public Sector and Government.",

    },
    {
        id: 18,
        header: "What BPO services do you offer?",
        body: "We offer customer support services, back office solutions, IT support services, and human resources outsourcing.",

    },
    {
        id: 19,
        header: "How can BPO services benefit my business?",
        body: "Our BPO services help streamline operations, reduce operational costs, enhance customer satisfaction, and allow you to allocate resources to more strategic functions.",

    },
    {
        id: 20,
        header: "What kind of customer support services do you provide?",
        body: "We provide 24/7 call center support, email support, and live chat support to ensure exceptional customer service around the clock.",

    },
    {
        id: 21,
        header: "What business development services do you provide?",
        body: "We offer market research and analysis, strategic planning, sales and marketing, financial management, operational efficiency, networking and partnerships, training and development, and legal and compliance services.",

    },
    {
        id: 22,
        header: "How can BDS help my business grow?",
        body: "Our BDS services help identify growth opportunities, streamline business processes, enhance operational efficiency, and develop effective sales and marketing strategies.",

    },
    {
        id: 23,
        header: "Can you help with strategic planning and business expansion?",
        body: "Yes, we provide comprehensive strategic planning services, including business planning, growth strategies, and SWOT analysis to support business expansion and diversification.",

    },
    {
        id: 24,
        header: "What IT development services do you offer?",
        body: "We offer website development, mobile application development, software development, cloud solutions, and cybersecurity services.",

    },
    {
        id: 25,
        header: "Can you develop custom software solutions?",
        body: "Yes, we develop custom software solutions tailored to meet your unique business requirements.",

    },
    {
        id: 26,
        header: "Do you provide ongoing maintenance and support for mobile apps?",
        body: "Yes, we offer ongoing maintenance and support to ensure your mobile applications run smoothly and efficiently.",

    },
    {
        id: 27,
        header: "What marketing and advertising services do you offer?",
        body: "We offer digital marketing, video production, print marketing and advertisements, social media promotions, and satellite marketing.",

    },
    {
        id: 28,
        header: "How can your marketing services boost my brand's visibility?",
        body: "Our marketing services include social media marketing, SEO, PPC advertising, and various forms of content creation and promotion to enhance your brand’s visibility and engagement.",

    },
    {
        id: 29,
        header: "Do you provide influencer marketing services?",
        body: "Yes, we collaborate with influencers to help you reach a wider audience and build brand credibility.",

    },
    {
        id: 30,
        header: "How do I get started with The Brand Bucks?",
        body: "You can get started by contacting us through phone at (+971) 55 253 4230 or email at info@thebrandbucks.com. We begin with a thorough consultation to understand your business requirements and challenges.",

    },
    {
        id: 31,
        header: "What is the process for working with The Brand Bucks?",
        body: "Our process involves an initial consultation, designing a customized solution based on your needs, smooth implementation, and continuous improvement to ensure optimal performance and satisfaction.",

    },
    {
        id: 32,
        header: "How do you ensure the quality and compliance of your services?",
        body: "We are committed to excellence and adhere to the highest standards of quality and regulatory compliance in all our services.",

    },

]
export default AccordionGQObject