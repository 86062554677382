import dollar from '../resources/dollar.png'
import org from '../resources/org.png'
import sales from '../resources/sales.png'
const ProvidesObject = 
[
    {
        img: sales,
        title: "Boost your sales",
        desc: "With the click of a button, Instantly boost your sales by listing your products and watch as 10,000 items fly off the shelves in no time!",
        to: '/brands-and-products',

    },

    {
        img: org,
        title: "Build your business",
        desc: "With the click of a button, Contact us to launch or grow your business, or acquire an already established one!",
        to: '/contact-us', 

    },
    {
        img: dollar,
        title: "Earn upto $1000",
        desc: "With the click of a button, Partner with us to review brands and products and earn upto $1000!",
        to: '/reviewers',

    },
]
export default ProvidesObject