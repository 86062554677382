import React, { useState } from 'react'
import Contactus from '../Objects/Contactus'
import './AboutUS.css'
import { motion } from 'framer-motion';
import './ContactUS.css'
const ContactUS = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "09299f6c-2cba-48d5-9c52-afeaa7f70412");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Your message has been sent successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  
  return (
    <div className=' w-[100%] h-[100%] text-center justify-center content-center'>
       <div className=' backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background md:p-12 p-3 '>
      <div className=' font-mon not-italic font-bold text-4xl text-white pt-12'>
        <p>Contact us</p>
      </div>
    </div>

      <div className=' p-8 text-center'>
        <h1 className=' font-mon not-italic font-extrabold text-4xl leading-lineHeight44 text-[#15191B]'>Get In <span className=' text-[#8B014A]'>Touch</span></h1>
        <p className='text-lg'>Contact us for a quote, help to join the team</p>

      </div>
      
      
      <div className="w-[100%] h-[100%] shadow-md">
      <section className="text-gray-700 body-font relative">
  <div className="container mx-auto rounded-lg p-6">
    <div className="lg:w-1/2 md:w-2/3 mx-auto bg-white shadow-lg rounded-lg p-6">
    <form onSubmit={onSubmit}>
      <div className="flex flex-wrap -mx-3 mb-6">
      
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <div className="relative">
            <input
              type="text"
              required
              id="name"
              name="name"
              placeholder=" "
              className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
            />
            <label
              htmlFor="name"
              className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
            >
              Name
            </label>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <div className="relative">
            <input
            required
              type="email"
              id="email"
              name="email"
              placeholder=" "
              className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
            />
            <label
              htmlFor="email"
              className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
            >
              Email
            </label>
          </div>
        </div>
        <div className="w-full mt-5 px-3 mb-6">
          <div className="relative">
            <input
            required
              type="text"
              id="subject"
              name="subject"
              placeholder=""
              className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
            />
            <label
              htmlFor="subject"
              className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
            >
              Subject
            </label>
          </div>
        </div>
        <div className="w-full px-3 mb-6">
          <div className="relative">
            <textarea
            required
              id="message"
              name="message"
              placeholder=" "
              className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 resize-none h-32 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
            ></textarea>
            <label
              htmlFor="message"
              className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
            >
              Message
            </label>
          </div>
        </div>
        <div className="w-full px-3">
        <motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
        type='submit'
  className="px-6 z-0 py-2 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative 
  font-base font-sans after:-z-20 after:absolute after:h-1 after:w-1 
  after:left-5 overflow-hidden after:bottom-0 
  after:translate-y-full after:rounded-md after:hover:scale-[300] 
  after:hover:transition-all after:hover:duration-700 after:transition-all 
  after:duration-700 transition-all duration-700
 text-2xl">
Submit</motion.button> 
<span>{result}</span>
        </div>
        
      </div>
      </form>
    </div>
  </div>
</section>




      <div className=' w-full sm:flex content-center text-center'>
      {
        Contactus.map((contact) =>
        {
          return(
            <>
            
            <div className=' sm:w-1/3 sm:p-2 p-5 justify-center content-center text-center'>
              <div className=' cards h-full'>
              <div className="hwl bg">
              
            <div className='image w-10 h-30 text-start justify-start' id='qwi'>
              <img src={contact.cerimg} />
            </div>
            <div id='qwi' className='p-2 email text-start text-[#8B014A] font-mon not-italic font-bold text-lg leading-6'>
              <p>{
                contact.textwri
              }</p>
            </div>
            <div className='emailid text-start p-2 font-mon not-italic font-medium text-base leading-5 text-[#626262]' >
              <div id='rti'><span className=' font-bold'>{contact.undert}</span>    <br/> <p id='' className=' font-mon font-semibold md:w-[85%] mx-auto text-[#626262] text-center pt--10'>{contact.po}</p></div>

            </div>
          </div>
          <div className='blob'></div>
          </div>
          </div>
              </> 
          )
        })
      }
        
       
      </div>
</div>
    </div>
  )
}

export default ContactUS
