import React from 'react'
import { Link } from 'react-router-dom'
import ProvidesObject from '../Objects/ProvidesObject'
import './Home.css'
import './ContactUS.css'

const MyPlugProvides = () => {

  
  return (
    <div className=' w-full h-full'>
      <div className=' content-center justify-center text-center p-4'>
   <div className=' sm:mx-auto'>
    <div className=''>
    <p className='text-center text-4xl leading-lineHeight44 font-extrabold font-mon not-italic text-[#15191B]'>Our <span className=' text-[#88014A]'> Oath</span></p>
    </div>
    <div >
    <p className='text-center font-mon not-italic mt-3 mb-4 text-base font-medium leading-7 text-[#626262] md:w-[70%] w-[90%] mx-auto'>
    At The Brand Bucks, we pledge to uphold the highest standards of integrity, 
    trustworthiness, and reliability. We are committed to delivering on our 
    promises with unwavering honesty and dependability. Our dedication to these 
    principles ensures that we remain steadfast in our mission, never swayed from 
    our path by external pressures. We honor this oath as the 
    foundation of our commitment to our clients, partners, and each other.
</p>

    </div>
    </div> 
      <div className=' md:p-0 content-center text-center md:flex w-full md:w-full' >
      
        {
            ProvidesObject.map((users) =>
            {
              return(
                <div className=' card hover:border-[#88014A] hover:border-[1px] mx-2 md:w-1/3 cursor-pointer rounded-3xl shadow-md '>
                <div className='cards h-full '>
                <div className='bg '>
          <Link to={users.to}>
          <div className='hwl'>

          <div className=''>
            <img className='mb-0' src={users.img}/>
          </div>
          <div className=' text-[#8B014A] font-mon not-italic font-bold text-lg leading-lineHeight22 p-2 text-start mt-2'>
            <p>{users.title} </p>
          </div>
          <div className='text-[#626262] text-center mx-auto p-2 font-mon not-italic text-base font-medium leading-6'>
            <p>
              {
                users.desc
              }
            </p>
          </div>
          </div>
       
          
          </Link>
          </div>
          <div className='blob'></div>
          </div>
          
        </div>

              )
            })
          }
      
        
      </div>
    </div>
    </div>
  )
}

export default MyPlugProvides
