import React from 'react';
import './AboutUS.css';

const TermsAndConditions = () => {
  const terms = {
    introduction: "Welcome to The Brand Bucks! By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.",
    sections: [
      {
        title: "Eligibility",
        content: [
          "You must be at least 18 years old and capable of forming a legally binding agreement to use our services.",
          "If you are accessing our services on behalf of a business entity, you represent and warrant that you have the authority to bind that entity to these terms and conditions."
        ]
      },
      {
        title: "Account Registration",
        content: [
          "You may be required to create an account to access certain features of our services.",
          "You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
          "You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account."
        ]
      },
      {
        title: "Security of Account",
        content: [
          "You agree to notify us immediately of any unauthorized use of your account or any other breach of security.",
          "We are not liable for any loss or damage arising from your failure to comply with this security obligation."
        ]
      },
      {
        title: "Service Usage",
        content: [
          'Our services are provided on an "as is" and "as available" basis. We do not guarantee uninterrupted access to our services or their availability at any particular time.',
          "You agree to use our services in compliance with all applicable laws, regulations, and these terms and conditions.",
          "You agree not to use our services for any illegal purpose or in violation of any applicable laws or regulations."
        ]
      },
      {
        title: "Intellectual Property",
        content: [
          "All content included in our services, including but not limited to text, graphics, logos, images, audio clips, video clips, and software, is the property of The Brand Bucks or its licensors and is protected by copyright laws.",
          "You may not use, reproduce, distribute, or create derivative works from any of the content without our express written permission."
        ]
      },
      {
        title: "Prohibited Activities",
        content: [
          "Using our services for any illegal purpose or in violation of any applicable laws or regulations.",
          "Attempting to interfere with, disrupt, or compromise the integrity or security of our services.",
          "Engaging in any form of unauthorized access to our systems or data.",
          "Using our services to transmit any viruses, worms, or malicious software.",
          "Engaging in any activity that imposes an unreasonable or disproportionately large load on our infrastructure."
        ]
      },
      {
        title: "Service Offerings",
        content: [
          "Product Reviews: Thorough, insightful evaluations tailored to meet the unique needs of our clients.",
          "Business Process Outsourcing (BPO):",
          [
            "Customer Support and Back-Office Solutions: Enhance efficiency and reduce costs by outsourcing these critical functions.",
            "IT Support and Human Resources Outsourcing: Focus on your core activities while we handle the intricacies of operational management.",
            "IT Development Services: Empower your business with custom website and mobile app development, software solutions, cloud services, and cybersecurity measures."
          ],
          "Business Development Services (BDS):",
          [
            "Market Research and Analysis: Understand industry trends, customer preferences, and competitive landscapes.",
            "Strategic Planning: Equip your business with tools for informed decision-making and long-term success through business planning, growth strategies, and SWOT analysis.",
            "Sales and Marketing Solutions: Boost your revenue with comprehensive marketing strategies including digital marketing, video production, print marketing, social media promotions, and satellite marketing.",
            "Financial Management: Optimize your resources and streamline processes.",
            "Operational Efficiency Services: Ensure your business operations are efficient and effective.",
            "Networking and Partnerships: Expand your reach and influence through strategic partnerships.",
            "Training and Development: Equip your team with the skills needed to excel.",
            "Legal and Compliance Services: Ensure your business adheres to all necessary regulations and standards."
          ]
        ]
      },
      {
        title: "Termination",
        content: [
          "We reserve the right to suspend or terminate your access to our services at any time and for any reason without prior notice.",
          "Upon termination, your right to use our services will immediately cease, and you must cease all use of our services."
        ]
      },
      {
        title: "Limitation of Liability",
        content: [
          "In no event shall The Brand Bucks, its officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, arising out of or in connection with your use of our services."
        ]
      },
      {
        title: "Indemnification",
        content: [
          "You agree to indemnify, defend, and hold harmless The Brand Bucks, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of or in any way connected with your access to or use of the services."
        ]
      },
      {
        title: "Privacy",
        content: [
          "We are committed to protecting your privacy. Please review our Privacy Policy to understand how we collect, use, and safeguard your information."
        ]
      },
      {
        title: "Governing Law",
        content: [
          "These terms and conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflicts of law principles."
        ]
      },
      {
        title: "Dispute Resolution",
        content: [
          "Any disputes arising out of or relating to these terms and conditions or our services shall be resolved through binding arbitration in accordance with the rules of [Arbitration Body], except for disputes related to intellectual property rights, which may be resolved in court."
        ]
      },
      {
        title: "Changes to Terms",
        content: [
          "We reserve the right to modify or revise these terms and conditions at any time, and such changes will be effective immediately upon posting on our website or notifying you via email.",
          "Your continued use of our services after such changes constitutes your acceptance of the new terms and conditions."
        ]
      },
      {
        title: "Contact Information",
        content: [
          "If you have any questions about these terms and conditions, please contact us at info@thebrandbucks.com"
        ]
      },
    ],
    conclusion: "By using our services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not use our services."
  };

  return (
    <div className='w-full h-full'>
      <div className='backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background md:p-12 p-7'>
        <p className='font-mon not-italic font-bold sm:text-4xl text-xl text-white pr-5 pt-10 md:pt-12 '>Terms and Conditions</p>
      </div>
      <div className='where w-[90%] mx-auto mt-10'>
        <div className='mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <p>{terms.introduction}</p>
        </div>
        {terms.sections.map((section, index) => (
          <div key={index} className='mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
            <h2 className='mt-2 font-bold text-[#88014A]'>{section.title}:</h2>
            <ul className='mt-2 ml-5 list-disc'>
              {section.content.map((item, idx) => (
                Array.isArray(item) ?
                  <ul key={idx} className='ml-5 list-disc'>
                    {item.map((subitem, subidx) => (
                      <li key={subidx}>{subitem}</li>
                    ))}
                  </ul>
                  :
                  <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className='mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <p>{terms.conclusion}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
