import React from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import dm from '../../resources/dm.jpg'
import vp from '../../resources/vp.jpeg'
import smm from '../../resources/smm.jpg'
import ga from '../../resources/ga.jpg'
import pa from '../../resources/pa.jpeg'
import bds from '../../resources/bds.jpg'
import mra from '../../resources/mra.jpg'
import creative from '../../resources/creative.jpeg'
import { Link } from 'react-router-dom';


const AdvertisingMarketingServices = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const services = [
    {
      img: dm,
      title: "Digital Marketing",
      description: "Harness the power of digital channels to reach your target audience with our extensive digital marketing services. We create and execute strategies that deliver measurable results.",
      points: [
        "Search Engine Optimization (SEO)",
        "Pay-Per-Click (PPC) Advertising",
        "Social Media Marketing",
        "Content Marketing",
        "Email Marketing",
      ],
    },
    {
      img: vp,
      title: "Video Production",
      description: "Create compelling visual content that captivates your audience. Our video production services include everything from concept development to post-production.",
      points: [
        "TV Commercials",
        "Social Media Ads",
        "Corporate Videos",
        "Product Demos",
        "Brand Storytelling",
      ],
    },
    {
      img: smm,
      title: "Social Media Management",
      description: "Build a strong online presence and engage with your audience on social media platforms. We manage your social media accounts to drive engagement and brand loyalty.",
      points: [
        "Social Media Strategy",
        "Content Creation and Curation",
        "Community Management",
        "Social Media Advertising",
        "Analytics and Reporting",
      ],
    },
    {
      img: ga,
      title: "Animation and Motion Graphics",
      description: "Enhance your marketing campaigns with eye-catching animations and motion graphics. We create visually engaging content that communicates your message effectively.",
      points: [
        "2D and 3D Animations",
        "Explainer Videos",
        "Infographics",
        "Motion Graphics for Ads",
        "Animated Logo Design",
      ],
    },
    {
      img: pa,
      title: "Print Advertising",
      description: "Reach your audience with high-quality print advertisements. We design and produce print materials that leave a lasting impression.",
      points: [
        "Magazine and Newspaper Ads",
        "Brochures and Flyers",
        "Posters and Banners",
        "Direct Mail Campaigns",
        "Catalogs and Booklets",
      ],
    },
    {
      img: bds,
      title: "Brand Development and Strategy",
      description: "Establish a strong brand identity and strategy that sets you apart from the competition. We help you define and communicate your brand's unique value proposition.",
      points: [
        "Brand Identity Design",
        "Brand Positioning",
        "Brand Messaging",
        "Rebranding Services",
        "Brand Guidelines",
      ],
    },
    {
      img: mra,
      title: "Market Research and Analysis",
      description: "Make informed marketing decisions with our thorough market research and analysis services. We provide insights into your market, competition, and customer behavior.",
      points: [
        "Market Segmentation",
        "Consumer Insights",
        "Competitive Analysis",
        "SWOT Analysis",
        "Industry Trends",
      ],
    },
    {
      img: creative,
      title: "Creative Services",
      description: "Bring your ideas to life with our creative services. Our team of designers and writers work together to create compelling content and visuals that resonate with your audience.",
      points: [
        "Graphic Design",
        "Copywriting",
        "Art Direction",
        "Campaign Concept Development",
        "Photography",
      ],
    },
  ];

  const benefits = [
    {
      title: 'Comprehensive Solutions',
      description: 'We offer a full spectrum of advertising and marketing services to meet all your business needs under one roof.',
    },
    {
      title: 'Experienced Team',
      description: 'Our team of professionals brings extensive experience and creativity to every project, ensuring high-quality results.',
    },
    {
      title: 'Customized Strategies',
      description: 'We understand that every business is unique. Our services are tailored to meet your specific goals and objectives.',
    },
    {
      title: 'Innovative Approaches',
      description: 'We use the latest trends and technologies to create innovative marketing solutions that stand out in the marketplace.',
    },
    {
      title: 'Results-Driven',
      description: 'Our focus is on delivering measurable results that drive your business growth and success.',
    },
  ];

  return (
    <div>
      <div className='relative backgroundBlack bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold md:text-4xl text-2xl text-white pt-12'>
          <p>Advertising and Marketing Services</p>
        </div>
      </div>
      
      <div className='w-full'>
        <div className='mx-auto md:p-10 p:1'>
          <motion.div
            initial='hidden'
            whileInView='visible'
            variants={itemVariants}
            transition={{ duration: 0.5 }}
            className='pl-4 pr-4 pt-3'
            viewport={{ once: false }}
          >
            <div className='text-start font-semibold text-lg'>
              <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                Welcome to The Brand Bucks, where we provide a comprehensive range of advertising and marketing services designed to elevate your brand and reach your target audience effectively.
              </p>
              <div className='right p-4 w-full'>
      <div>
        <ReactPlayer
          url='https://youtu.be/dP35d6Xw5-0?si=MPz8PA-jtqKqtbeb'
          width='100%'
          height={400}
          controls={true}
          className='rounded-3xl react-player shadow-lg'
        />
      </div>
    </div>
            </div>
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <div
                  className={`md:flex w-full ${index % 2 === 0 ? '' : 'flex-row-reverse justify-start text-start'} items-start mb-5`}
                  style={{ marginRight: index % 2 !== 0 ? '1%' : '0' }}
                >
                  <div className={`text-start ${index % 2 === 0 ? '' : ' ml-5'} md:w-[60%]`}>
                    <h1 className='font-bold text-[#8B014A] md:text-xl text-xl leading-normal'>{service.title}</h1>
                    <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{service.description}</p>
                    <ul className='list-disc pl-5'>
                      {service.points.map((point, i) => (
                        <li key={i} className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{point}</li>
                      ))}
                    </ul>
                  </div>
                  <div className='right md:w-[40%]'>
                    <img className='rounded-md shadow-lg' src={service.img} alt={service.title} key={service.index}/>
                  </div>
                </div>
              </motion.div>
            ))}
        
<div className="flex justify-center items-center">
<Link to='/contact-us'>
<motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
    className="flex justify-center items-center text-center whitespace-nowrap px-6 py-3 sm:px-6 sm:py-3 text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#8B014A] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-sm sm:text-base md:text-lg">

Discuss Your Marketing Needs</motion.button></Link> </div> 
          </motion.div>
        </div>
      </div>
      
      <div className='w-full mt-10'>
        <div className='mx-auto w-[90%] p-3 rounded-md shadow-lg'>
          <h2 className='font-mon not-italic text-start font-bold md:text-3xl text-2xl text-[#15191B]'>Why Choose The Brand Bucks?</h2>
          <div className='mt-5 text-start'>
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <h3 className='font-semibold md:text-xl text-base leading-normal text-[#88014A]'>{benefit.title}</h3>
                <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingMarketingServices;
