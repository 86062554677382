import React from 'react';

const OurProcess = () => {
  const steps = [
    {
      title: "Consultation",
      description: "We begin with a thorough consultation to understand your business requirements and challenges."
    },
    {
      title: "Customization",
      description: "Based on your needs, we design a customized solution that aligns with your goals."
    },
    {
      title: "Implementation",
      description: "Our team ensures a smooth transition and seamless integration with minimal disruption."
    },
    {
      title: "Continuous Improvement",
      description: "We continuously monitor and refine our processes to ensure optimal performance and satisfaction."
    }
  ];

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-bold text-[#88014A] mb-6 text-center animate__animated animate__fadeInDown">Our Process</h2>
      <div className="grid grid-cols-1 md:grid-cols-2">
        {steps.map((step, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg p-4 m-2 transition-transform transform hover:scale-105">
            <h3 className="md:text-2xl text-xl font-semibold text-left text-[#88014A] mb-1">{step.title}</h3>
            <p className="text-gray-700 font-semibold text-left">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurProcess;
