import React from 'react'
import './AboutUS.css'

const ReturnPolicy = () => {
  return (
    <div className=' w-full h-full'>
    <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B] md:p-12 p-6 '>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white pt-12'>Refund Policy</p>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#88014A]'>
        <p>Do you accept refund?</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>At The Brands Bucks, we prioritize maintaining the integrity and quality of our reviewing program to deliver valuable feedback for brands and products. As part of our commitment to ensuring dedicated participation, we have implemented a security deposit policy for new reviewers from some specific Countries from Asia.
<br/><br/>
Recognizing the prevalence of online scams in these regions, we have established three distinct reviewer categories: Silver, Gold, and Platinum each requiring a specific deposit amount. This deposit serves as a demonstration of our reviewers' commitment to the reviewing process and helps mitigate risks associated with potential misuse.

<div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <h2>Protocols and Policies for Reviewer Packages</h2>
          <h2 className=' mt-2 font-bold text-[#88014A]'>Package Tiers:</h2>
          
          <ul className=' mt-2 ml-5 list-disc'>
            <li>Reviewers are offered three packages: Silver, Gold, and Platinum.</li>
            <li>Each package entails 15 levels of participation.</li>
          </ul>
          
        </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <h2 className=' mt-2 font-bold text-[#88014A]'>Deposit Refund:</h2>
          
          <ul className=' mt-2 ml-5 list-disc'>
            <li>Upon completing 15 levels in any package, reviewers receive a full refund of their deposit automatically into their wallets.</li>
            <li>Reviewers have the option to withdraw this amount.</li>
          </ul>
          
        </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <h2 className=' mt-2 font-bold text-[#88014A]'>Package Continuation:</h2>
          
          <ul className=' mt-2 ml-5 list-disc'>
            <li>After completing one package, reviewers can choose to continue with the same plan or upgrade.</li>
            <li>Upgrading entails a 10% discount on the deposit amount of the new package.</li>
          </ul>
          
        </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <h2 className=' mt-2 font-bold text-[#88014A]'>Membership Cancellation:</h2>
          
          <ul className=' mt-2 ml-5 list-disc'>
            <li>If a reviewer decides to cancel their membership, they will receive their deposits back in their wallets after 90 days.</li>
            <li>This allows them to transact the refunded amount.</li>
            <li>Should a reviewer decide to discontinue their participation after completing a minimum of two tasks, they may request a refund of their deposit. However, refunds will only be processed at the conclusion of the three-month deposit period.</li>
            <li>This timeframe ensures that we maintain the seriousness and commitment of our reviewers to the reviewing process. By upholding these standards, we foster a transparent and mutually beneficial relationship between our reviewers and our company.
</li>
          </ul>
        </div>

<br/>

<p>These protocols and policies are designed to ensure clarity and professionalism in managing reviewer packages, deposits, renewals, upgrades, and cancellations.</p>
<br/>
Thank you for considering The Brands Bucks for your reviewing endeavors. We appreciate your dedication to excellence and look forward to collaborating with you. If you have any questions or require further assistance, please feel free to contact us.</p>

        </div>
      </div>
    </div>
  )
}

export default ReturnPolicy
