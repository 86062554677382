import React from 'react'

const TrackingYourOrder = () => {
  return (
    <div className=' w-full h-full'>
    <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B] p-16'>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white'>Return Policy</p>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>How can I track my package?
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>After you've placed your order, we'll send you a confirmation email so you can keep track of its progress. Once your order is shipped, we will send you another email to notify you of the expected arrival date and the link to monitor your order.  Additionally, you can track the status of your order from your "order history" section on your account page on the website.
<br/><br/>
Don't hesitate to get in touch directly with the Brand you purchased from to request a return or have any shipping concerns.


</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>Does My Plug offer in-store pickup? 
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Products can also be purchased for in-store pick-up, depending on the Brand’s in-store policy.

</p>

        </div>
      </div>
      </div>
  )
}

export default TrackingYourOrder
