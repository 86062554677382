import React from 'react'
import './AboutUS.css'
import AboutUSBG from './AboutUSBG'
import AboutUSImg from './AboutUSImg'
import MyPlugProvides from './MyPlugProvides'
import Ourblogs from './Ourblogs'
import Webinar from './Webinar'
import WeServe from './OurService/WeServe'
import WhyChooseUs from './WhyChooseUs'
import OurProcess from './OurProcess'

const AboutUS = () => {
  return (
    <div>
    <AboutUSBG/>
    <AboutUSImg/>
    <WeServe/>
    <WhyChooseUs/>
    <OurProcess/>
    <MyPlugProvides/>
    <Webinar/>
    <Ourblogs/>
    </div>
  )
}


export default AboutUS
