import React from 'react';
import { motion } from 'framer-motion';
import './AboutUS.css';

const PrivacyAndCookiePolicy = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  const privacyPolicy = {
    effectiveDate: "June 10, 2024",
    introduction: "At The Brand Bucks, we are committed to preserving the confidentiality and security of our clients' information. This privacy policy provides comprehensive insights into how we collect, utilize, and safeguard the information entrusted to us.",
    sections: [
      {
        title: "Information Collection",
        content: [
          "In order to render our services effectively, we require the following information from our clients:",
          "Business Name: To accurately identify the client's entity.",
          "License or Authenticity Proof of Business: To verify the legitimacy of the business entity.",
          "Owner or Manager ID: To establish accountability and ensure proper communication channels.",
          "Contact Details (Email and Phone Number): To facilitate communication and updates regarding our services.",
          "Complete Business Address: To ascertain the physical location of the client's business.",
          "Age of Brand or Product: To understand the lifecycle stage and specific needs of the client."
        ]
      },
      {
        title: "Data Usage",
        content: [
          "The data collected is utilized for the following purposes:",
          "Authentication: Verifying the client's business identity.",
          "Service Provision: Delivering and improving our BPO, BDS, IT Development, and Marketing and Advertising services.",
          "Analysis: Deriving insights from the provided information to enhance the quality of our services.",
          "Communication: Keeping clients informed about service updates, offers, and relevant information.",
          "Security: Upholding the highest standards of privacy and security to safeguard all information."
        ]
      },
      {
        title: "Data Sharing",
        content: [
          "We will not share any of your personal details with any unauthorized company or person. Your personal data is handled with strict confidentiality and will only be shared with third parties in accordance with this Privacy Policy and applicable laws"
        ]
      },
      {
        title: "Security Measures",
        content: [
          "We maintain stringent security protocols and employ robust measures to safeguard the confidentiality and integrity of our clients' data. Our dedicated team is entrusted with the responsibility of ensuring adherence to these measures. We do not outsource any critical services or tools to third-party providers to mitigate potential risks.",
          "Our security measures include:",
          "Data Encryption: Using advanced encryption methods to protect data in transit and at rest.",
          "Access Controls: Restricting access to client information to authorized personnel only.",
          "Regular Audits: Conducting regular security audits to identify and address vulnerabilities.",
          "Employee Training: Training our staff on data protection best practices and compliance requirements."
        ]
      },
      {
        title: "User Rights",
        content: [
          "We recognize and respect the rights of our clients, which include:",
          "Right to Inquiry: The right to inquire about the completion timeframe of their work.",
          "Right to Status Request: The right to request the current status of their work at any given time.",
          "Right to Data Access: The right to access the personal data we hold about them.",
          "Right to Data Rectification: The right to request corrections to any inaccurate or incomplete personal data.",
          "Right to Data Erasure: The right to request the deletion of their personal data, subject to legal and contractual constraints.",
          "Right to Restriction of Processing: The right to request the restriction of processing their personal data under certain conditions.",
          "We are committed to addressing any queries or concerns promptly and transparently."
        ]
      },
      {
        title: "Cookies & Support",
        content: [
          "Our website utilizes cookies to enhance the browsing experience and provide personalized content tailored to individual preferences. Cookies help us understand user behavior, track service performance, and improve our offerings.",
          "We offer 24/7 customer support to address any inquiries or assistance required by our clients."
        ]
      },
      {
        title: "Legal Compliance",
        content: [
          "We adhere strictly to all relevant data protection laws and regulations, including but not limited to the General Data Protection Regulation (GDPR) and the FTC Act. Our compliance efforts are continuously reviewed and updated to ensure alignment with evolving regulatory requirements."
        ]
      },
      {
        title: "Policy Updates",
        content: [
          "In the event of any updates or amendments to our privacy policy, we undertake to notify our clients via email and newsletters to ensure transparency and awareness of any changes."
        ]
      },
      
      {
        title: "Contact Information",
        content: [
          "For any questions, clarifications, or concerns regarding our privacy policy or data handling practices, please do not hesitate to reach out to us. Your feedback is invaluable to us in our ongoing efforts to uphold the highest standards of privacy and security.",
          "Contact Us:",
          "The Brand Bucks",
          "Phone: (+971) 55 253 4230",
          "Email: info@thebrandbucks.com",
          "Thank you for trusting The Brand Bucks as your partner in growth and efficiency."
        ]
      }
    ]
  };

  return (
    <div className='w-full h-full'>
   
   <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B] md:p-12 p-6 '>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white pt-12'>Privacy Policy</p>
      </div>
      <div className='where w-[90%] mx-auto mt-10'>
        <div className='mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
          <p>{privacyPolicy.introduction}</p>
        </div>
        {privacyPolicy.sections.map((section, index) => (
          <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className='mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
            <h2 className='mt-2 font-bold text-[#88014A]'>{section.title}:</h2>
            <ul className='mt-2 ml-5 list-disc'>
              {section.content.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyAndCookiePolicy;
