import React from 'react'
import fb from '../resources/fb.png'
import insta from '../resources/insta.png'
import twitter from '../resources/twitter.png'
import tiktok from '../resources/tiktok.png'
import logo from '../resources/logo.png'
import appstore from '../resources/ICC.png'
import playstore from '../resources/tise.png'
import UsefulLinks from '../Objects/UsefulLinks'
import { Link, NavLink } from 'react-router-dom'


const Footer = () => {
  return (
      <footer className=" w-full text-gray-600">
  <div className="flex justify-center items-center p-4 border-b border-gray-300">
    
  </div>
    <div className=" w-[90%] mx-auto md:flex ">
      <div className=" md:w-1/3 w-full ">
      
        <Link to='/'><img src={logo} className=" mx-auto w-64 md:mx-0"/></Link>
        <p className=' text-[#626262] font-mon md:text-left text-center not-italic font-medium text-sm leading-7'>
        <span className=' text-[#8B014A] font-bold'>"Where every review generates revenue"</span></p>
        <p className=' font-mon not-italic md:text-left text-center font-medium text-sm leading-lineHeight25'>
        Crafting Brands, Amplifying Revenue, 
        and Inspiring Rave Reviews  Elevating 
        Your Business Beyond Expectations!


        </p>
        
      </div>
      
      
      <div className="w-full mt-5 md:w-1/3  ">
        <div className='md:mb-4 text-center mt-3'>
          <p className=' font-bold text-lg font-mon'>Affiliations</p>
        </div>

        <a href='https://iccwbo.org/' target='__blank'><img className='items-center justify-center md:justify-center mb-4 cursor-pointer mx-auto' src={appstore}/></a>
            <a href=' https://tisegroup.com/' target='__blank'><img className=' md:cursor-pointer w-[256px] h-24 cursor-pointer mx-auto' src={playstore}/></a>
      </div>
      <div className=" mx-auto md:mx-auto mt-5 w-full md:w-1/3  ">
      <div className='md:mb-4 md:ml-0 mx-auto mt-3'>
          <p className=' mx-auto font-bold text-lg font-mon'>Useful Links</p>
        </div>
        {
          UsefulLinks.map((useful) =>
          {
            return(
              <>
              <p className="mb-2 mt-3 text-[#515869] hover:text-[#8B014A] font-mon not-italic font-medium text-sm leading-4">
          <NavLink to={useful.to} className={({ isActive}) =>{return(' text-[#8B014A]' +  (isActive ? ' text-[#8B014A]' : 'text-[#15191B]'))}}>{useful.name}</NavLink>
        </p>
              </>
            )
          })
        }
      </div>
    </div>
    <div className=' md:w-[90%]  justify-center md:mx-auto mt-6 items-center lg:justify-between'>
    <p className=' font-mon not-italic font-bold text-base md:text-start leading-7'>Follow us</p>
    
    <div className=" mt-3 mx-auto md:w-full w-full ">
    <div className="flex mx-aut  justify-center content-center md:justify-start">
      <a target={'_blank'} href='https://www.facebook.com/Thebrandbucks'><img src={fb} className=" text-gray-600 cursor-pointer"/>
      </a>
      <a target={'_blank'} href='https://www.instagram.com/thebrandbucks.co'><img src={insta} className=" ml-7 text-gray-600 cursor-pointer"/>
      </a>
      <a target={'_blank'} href='https://x.com/the_brandbucks'><img src={twitter} className="ml-7 text-gray-600 cursor-pointer"/>
      </a>
      <a target={'_blank'} href='https://www.tiktok.com/@thebrandbucks'><img src={tiktok} className="ml-7 text-gray-600 cursor-pointer"/>
      </a>
      <a target={'_blank'} href='https://www.linkedin.com/company/thebrandbucks/?viewAsMember=true'><div className="ml-7 text-gray-600 w-[30px] h-[30px] bg-[#0078D4] rounded-full border text-center"><p className=' font-mon text-lg font-bold cursor-pointer text-[#FFFFFF]'>in</p></div>
      </a>
    </div>
    </div>
  </div>
  
  <div className="text-center mx-auto p-4 bg-white text-[#515869] font-mon not-italic font-medium text-sm leading-lineHeight17">
    <p>© 2023 Copyright: The Brand Bucks</p>
  </div>
</footer>
  )
}

export default Footer
