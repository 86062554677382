import React from 'react'
import { Link } from 'react-router-dom'
import BlogObj from '../Objects/BlogObj'

const Ourblogs = () => {
  return (
    <div className=' w-[90%] mx-auto content-center justify-center text-center'>
    <div className=' mt-5'>
    <div className=' text-center text-4xl leading-lineHeight44 font-extrabold font-mon not-italic text-black'>
    <p className=' font-mon not-italic font-extrabold text-2xl md:text-4xl text-[#8B014A]'>Our Motive</p>
    </div>
    <div className=' text-center font-mon not-italic text-base font-bold leading-6'>
    <p>Where every review generates revenue.
</p>

    </div>
    </div>
      <div className=' md:p-0 content-center text-center md:flex w-full md:w-full'>
      
        {
            BlogObj.map((users) =>
            {
              return(
                
                <div className='hello card hover:border-[#8B014A] hover:border m-5 md:mx-4 mx-auto p-5 md:w-1/3 w-full cursor-pointer rounded-3xl shadow-md'>
          <Link to={users.to}>
          <div className=' mx-auto'>
            <img className=' mx-auto rounded-lg' src={users.img}/>
          </div>
          <div className=' font-mon mx-auto font-bold text-sm text-white leading-3 rounded-lg w-20 p-2 bg-[#8B014A] text-center mt-5'>
            <p className=' mx-auto'>{users.job}</p>
          </div>
          <div className=' mt-4 font-mon text-center not-italic font-bold text-lg leading-7 text-[#15191B]'>
            <p className=' uppercase mx-auto'>
              {
                users.title
              }
            </p>
          </div>
          <div className=' justify-center pt-4 flex content-center'>
              <div className=' pt-1 timer'>
                <img className='' src={users.timer}/>
              </div>
                <div className=' font-mon font-medium text-sm leading-4 text-[#626262] pt-1 ml-1 read'>
                <p>
                  {
                    users.readTime
                  }
                </p>
                </div>
                  <div className=' pt-1 pl-3 calendar'>
                      <img src={users.calendar}/>
                      </div>
                  <div className=' font-mon font-medium text-sm leading-4 text-[#626262] posted ml-1 pt-1'>
                  <p>
                    {
                      users.timePosted
                    }
                  </p>
                  </div>
          </div>
          </Link>
        </div>
              )
            })
          }
      
        
      </div>
    </div>
  )
}

export default Ourblogs
