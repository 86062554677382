import React from 'react'
import MyPlugProvides from './MyPlugProvides'

import NewsLetter from './NewsLetter'
import WelcomeToMyPlug from './WelcomeToMyPlug'
import Articles from './Articles'
import Webinar from './Webinar'
import MainBanner from './MainBanner'

const Home = () => {
  return (
    <div className="w-full">
  
      <MainBanner/>
      {/* <OurPartners/> */}
      <WelcomeToMyPlug/>
      <Articles/>
      <Webinar/>
      <MyPlugProvides/>
      <NewsLetter/>
    </div>
  )
}

export default Home
