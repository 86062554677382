import React from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
const Webinar = () => {

  const itemVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 10 },
  };

  return (
    <div className="flex items-center justify-center w-full px-4 py-6 sm:px-6 md:px-8 lg:px-12">
      <div className="relative w-full max-w-6xl mx-auto shadow-lg" >
        <p className="pb-4 pt-6 font-mon not-italic font-extrabold text-xl md:text-3xl lg:text-4xl">
          Check out our <span className="text-[#8B014A]">latest webinar</span>
        </p>
        <p className="font-mon font-semibold text-base mb-0">Video introduction to The Brand Bucks</p>
        <div className='w-full'>
        <div className='mx-auto md:p-4 p:1 pl-2'>
          <motion.div
            initial='hidden'
            whileInView='visible'
            variants={itemVariants}
            transition={{ duration: 0.5 }}
            className=' pt-3'
            viewport={{ once: false }}>
          
            
              <div className='right p-0 w-full h-300 md:h-500'>
      <div className='video-wrapper'>
        <ReactPlayer
          url='https://youtu.be/VkiL11ijx8s?si=U_sa2G7m0cYqnLQi'
          width='100%'
          height={400}
          controls={true}
          className='react-player shadow-lg '
        />
    
    </div>
            </div>
    
          </motion.div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Webinar;
