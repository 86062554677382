import React from 'react'
import blog1 from '../../resources/tbb.jpg'
const ElevenTopTipBlog = () => {
  return (
    <div className=' w-full h-full'>
    <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B]  md:p-10 p-3'>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white pt-10 pb-2'>Our Blogs</p>
      </div>
      <div className=' w-[50%] mx-auto mt-10'>
        <img className=' mx-auto rounded-lg' src={blog1}/>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Welcome to The Brand Bucks! <br/><br/>

At <span className=' text-[#8B014A] font-bold'>The Brand Bucks</span>, our mission is to set the gold standard for excellence in brand reviews and consumer engagement. We are driven by a relentless pursuit of perfection, always striving to deliver meticulous, insightful reviews that propel brands towards unparalleled success. Our platform serves as a powerful tool for brands, providing them with invaluable data and insights to fuel their growth and innovation.
<br/><br/>
But we're more than just a platform—we're a community of dedicated individuals united by a common passion for excellence and a shared commitment to driving positive change in the industry. Our reviewers are not just experts; they are ambassadors of quality and integrity, each contributing their unique expertise to our collective mission.
<br/><br/>
Join us on this journey as we revolutionize the way brands interact with their audiences and shape the future of consumer experiences. Together, let's unlock the full potential of insightful reviews to drive positive change and elevate the standards of excellence in the industry.
<br/><br/>
At The Brand Bucks, we believe that every review is an opportunity to make a difference. Join our community today and become a part of something truly extraordinary.
<br/><br/>
Thank you for choosing to be a part of The Brand Bucks family.
<br/><br/>
{/* <span className=' font-bold'>Muhammad Usman Zubair (CEO)</span> */}
</p>

        </div>
      </div>
      
      
      
      </div>
  )
}

export default ElevenTopTipBlog
