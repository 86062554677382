import React from 'react'
const WelcomeToMyPlug = () => {
  return (
    <div className=' myplug-div text-center content-center justify-center w-full h-full bg-white'>
    <div className='welcomes m-4 content-center'>
        <div className=''>
            <p className='font-mon not-italic font-extrabold text-2xl md:text-4xl'>Explore <span className=' text-[#8B014A]'>The Brand Bucks</span></p>
        </div>
        <p className=' font-semibold mt-5 text-xl md:text-2xl'> Your Partner in Growth and Efficiency
 </p>
        <div className=' mt-4 content-center text-center
         text-[#626262] mx-auto font-mon not-italic md:p-0 p-2
        font-medium leading-6 text-lg w-full md:w-[90%] h-auto'>
        
            <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>At The Brand Bucks, we drive business success 
            through strategic enhancement, comprehensive product 
            reviews, and extensive Business Process Outsourcing (BPO) 
            and Business Development Services (BDS). Our tailored 
            solutions in customer support, IT support, and human 
            resources outsourcing help businesses enhance efficiency 
            and focus on core activities. We also offer market research, 
            strategic planning, sales and marketing solutions, financial 
            management, and operational efficiency services to accelerate 
            growth. Additionally, our video production services create 
            impactful TV commercials and social media ads. Integrity, 
            professionalism, and excellence are at our core, fostering 
            long-term partnerships built on trust and transparency. 
            Become a member to earn from home and unlock your business's 
            full potential in a competitive market.

</p>
        </div>
    </div>
    
    </div>
  )
}

export default WelcomeToMyPlug
