import React from 'react';
import { motion } from 'framer-motion';
import mra from '../../resources/mra.jpg' // Add your images accordingly
import sp from '../../resources/sp.jpeg'
import ssp from '../../resources/ssp.png'
import mb from '../../resources/mb.jpg'
import pa from '../../resources/pa.jpeg'
import pd from '../../resources/pd.png'
import fpa from '../../resources/fpa.jpg'
import oe from '../../resources/oe.png'
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
const BDSPage = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const services = [
    {
      img: mra,
      title: "Market Research and Analysis",
      description: "Gain deep insights into your market with our thorough research and analysis services. We provide actionable data to inform your business strategies and decisions.",
      points: [
        "Market Segmentation",
        "Competitive Analysis",
        "Consumer Behavior Studies",
        "Industry Trend Analysis",
        "SWOT Analysis",
      ],
    },
    {
      img: sp,
      title: "Strategic Planning",
      description: "Develop robust strategies that align with your business goals. Our strategic planning services help you chart a clear path to success.",
      points: [
        "Business Plan Development",
        "Growth Strategy Formulation",
        "Feasibility Studies",
        "Risk Management",
        "Strategic Roadmaps",
      ],
    },
    {
      img: ssp,
      title: "Sales Strategy and Support",
      description: "Enhance your sales performance with our comprehensive sales strategy and support services. We help you create and implement effective sales plans that drive revenue growth.",
      points: [
        "Sales Process Optimization",
        "Lead Generation",
        "Sales Training and Coaching",
        "CRM Implementation and Management",
        "Sales Performance Analytics",
      ],
    },
    {
      img: mb,
      title: "Marketing and Branding",
      description: "Boost your brand visibility and market reach with our marketing and branding services. We create compelling campaigns that resonate with your target audience.",
      points: [
        "Brand Development and Positioning",
        "Digital Marketing",
        "Content Marketing",
        "Social Media Management",
        "Advertising Campaigns",
      ],
    },
    {
      img: pa,
      title: "Partnership and Alliances",
      description: "Expand your business opportunities through strategic partnerships and alliances. We help identify and cultivate beneficial collaborations.",
      points: [
        "Partnership Identification",
        "Negotiation and Deal Structuring",
        "Joint Ventures",
        "Alliance Management",
        "Networking Opportunities",
      ],
    },
    {
      img: pd,
      title: "Product Development and Launch",
      description: "Successfully bring new products to market with our product development and launch services. We guide you through every stage of the product lifecycle.",
      points: [
        "Market Research and Validation",
        "Product Design and Development",
        "Prototyping and Testing",
        "Go-to-Market Strategy",
        "Product Launch Management",
      ],
    },
    {
      img: fpa,
      title: "Financial Planning and Analysis",
      description: "Ensure financial stability and growth with our financial planning and analysis services. We provide expert guidance to manage your finances effectively.",
      points: [
        "Financial Forecasting",
        "Budgeting and Cost Control",
        "Investment Analysis",
        "Fundraising Support",
        "Financial Performance Monitoring",
      ],
    },
    {
      img: oe,
      title: "Operational Efficiency Improvement",
      description: "Optimize your operations to achieve maximum efficiency and productivity. Our services help streamline processes and reduce operational costs.",
      points: [
        "Process Reengineering",
        "Workflow Optimization",
        "Supply Chain Management",
        "Performance Metrics Development",
        "Technology Integration",
      ],
    },
  ];

  const benefits = [
    {
      title: 'Experienced Professionals',
      description: 'Our team consists of seasoned professionals with extensive experience in business development across various industries.',
    },
    {
      title: 'Customized Solutions',
      description: 'We understand that every business is unique. Our services are tailored to meet your specific needs and objectives.',
    },
    {
      title: 'Results-Oriented Approach',
      description: 'We focus on delivering measurable results that drive your business growth and success.',
    },
    {
      title: 'Innovative Strategies',
      description: 'We employ innovative strategies and the latest industry practices to ensure your business stays ahead of the competition.',
    },
    {
      title: 'Commitment to Excellence',
      description: 'At The Brand Bucks, we are committed to providing high-quality services that exceed your expectations.',
    },
  ];

  return (
    <div>
      <div className='relative backgroundBlack bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold md:text-4xl text-2xl text-white pt-12'>
          <p>Business Development Services</p>
        </div>
      </div>

      <div className='w-full'>
        <div className='mx-auto md:p-10 p:1 pl-2'>
          <motion.div
            initial='hidden'
            whileInView='visible'
            variants={itemVariants}
            transition={{ duration: 0.5 }}
            className=' pt-3'
            viewport={{ once: false }}>
            <div className=' font-semibold text-lg'>
              <p className=' text-start pr-2 pl-2 pb-2 font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                Welcome to The Brand Bucks, your trusted partner in Business Development Services (BDS). We provide comprehensive and customizable solutions designed to help businesses streamline operations, reduce costs, and enhance productivity. Our services cover a broad spectrum of business needs, ensuring that you can focus on your core competencies while we manage the rest.
              </p>
              <div className='right pr-4 w-full'>
      <div className='video-wrapper'>
        <ReactPlayer
          url='https://youtu.be/OSxm2h6aPCM?si=MdHMGkWiwnBjrYw3'
          width='100%'
          height={400}
          controls={true}
          className='rounded-3xl react-player shadow-lg'
        />
      </div>
    </div>
            </div>
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <div
                  className={`md:flex w-full ${index % 2 === 0 ? '' : 'flex-row-reverse justify-start text-start'} items-start mb-5`}
                  style={{ marginRight: index % 2 !== 0 ? '1%' : '0' }}
                >
                  <div className={`text-start ${index % 2 === 0 ? '' : ' ml-5'} md:w-[60%]`}>
                    <h1 className='font-bold text-[#8B014A] md:text-xl text-xl leading-normal'>{service.title}</h1>
                    <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{service.description}</p>
                    <ul className='list-disc pl-5'>
                      {service.points.map((point, i) => (
                        <li key={i} className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='right md:w-[40%]'>
                    <img src={service.img} alt={service.title} className='rounded-md shadow-lg' />
                  </div>
                </div>
              </motion.div>
            ))}
   
<div className="flex justify-center items-center">
<Link to='/contact-us'>
<motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
    className="flex justify-center items-center text-center whitespace-nowrap px-6 py-3 sm:px-6 sm:py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#8B014A] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-lg shadow-lg sm:text-base md:text-lg">

Contact Us for BD Needs</motion.button>
</Link></div>       
          </motion.div>
        </div>
      </div>

      <div className='w-full mt-10'>
        <div className='mx-auto w-[90%] text-start p-3 rounded-md shadow-lg'>
          <h2 className='font-mon not-italic font-bold md:text-3xl text-2xl text-[#15191B]'>
            Why Choose <span className=' text-[#88014A]'>The Brand Bucks?</span>
          </h2>
          <div className='mt-5'>
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <h3 className='font-semibold md:text-lg text-base leading-normal text-[#88014A]'>{benefit.title}</h3>
                <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BDSPage;
