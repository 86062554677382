import React from 'react'
import about from '../resources/brandbucks.jpeg'
import about2 from '../resources/bucks.jpeg'
import WelcomeToMyPlug from './WelcomeToMyPlug'
import { motion } from 'framer-motion';

const AboutUSImg = () => {
  return (
    <div className=' p-4'>
      <div className=' md:content-center mx-auto md:w-[90%] md:justify-center md:text-center about-my-plug md:mt-3 md:flex'>
   
    <motion.div
          className='md:w-[40%] w-full ml-0 md:ml-3 mt-9'
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
            >
    <img className='rounded-md shadow-lg ' src={about2}/>

    </motion.div>
    <div className=' md:ml-3 about md:w-[60%] content-start text-start'>
    <div className=''>
    <p className='font-mon not-italic font-extrabold text-2xl md:text-4xl'> Overview <span className=' text-[#8B014A]'>The Brand Bucks</span></p>
    </div>
    <div className=''>
    
      <p  className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
      At The Brand Bucks, we drive business success through 
      strategic enhancement, comprehensive product reviews, and 
      extensive Business Process Outsourcing (BPO) and Business Development 
      Services (BDS). Our expert team provides tailored solutions in 
      customer support, IT support, and human resources outsourcing, 
      helping businesses enhance efficiency and focus on core activities. 
      We also offer market research, strategic planning, sales and 
      marketing solutions, financial management, and operational 
      efficiency 
      services to accelerate business growth and optimize resources.

      <br/>
Our product review process ensures thorough, 
insightful evaluations tailored to meet client needs, 
empowering businesses to make informed decisions and refine their 
strategies. Our marketing services boost brand visibility through digital 
marketing, video production, social media promotions, and more. We serve 
diverse industries, delivering scalable, customized solutions adhering 
to the highest standards of quality and compliance.
<br/>
Integrity, professionalism, and excellence are at the 
core of The Brand Bucks. We foster long-term partnerships 
built on trust and transparency, committed to helping businesses 
achieve new heights of efficiency and success. Partner with us today 
to unlock your business's 

full potential and thrive in a competitive market.<br/><br/>

      </p>
    </div>
    </div>
    </div>
    <div>
  <p className='font-mon not-italic font-extrabold text-2xl md:text-4xl mt-4'>
    Explore <span className='text-[#8B014A]'>The Brand Bucks</span>
  </p>
</div>

    <div className=' md:content-center md:w-[90%] md:justify-center md:text-center about-my-plug mx-auto md:mt-10 md:flex'>
    
    <div className=' about md:w-[60%] content-start text-start'>
    <div className=' font-mon not-italic font-extrabold text-xl md:text-4xl leading-lineHeight44'>
    </div>
    <p className=' md:text-left text-left font-bold mt-2 text-lg'>Brands, Reviews & Revenue. </p>
    <div className=''>
      
      <p className=' font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>The Brand Bucks, the foremost authority in delivering 
      insightful and impactful brand and product reviews.<br/><br/></p>
      <p className=' font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
      <span className=' text-lg font-bold'>Reviews: </span>
At The Brand Bucks, our seasoned team 
of expert reviewers meticulously assesses brands 
and products, delivering comprehensive insights that 
serve as a guiding light for consumers in making informed decisions. 
Through our rigorous evaluation process, we ensure unparalleled quality 
and depth in every review, empowering individuals to navigate 
the marketplace with confidence and clarity.
<br/><br/>
      </p>

      <p className=' font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
      <span className=' text-lg font-bold'>Revenue: </span>
Our cutting-edge review platform isn't 
just a conduit for consumer enlightenment; 
it's also a catalyst for accelerated growth for brands. 
By harnessing the potency of genuine feedback garnered from our 
platform, brands experience a tangible uptick in growth, marked by 
heightened visibility, enhanced credibility, and increased revenue 
streams. Concurrently, our esteemed reviewers are duly compensated 
for their invaluable contributions to the review process, fostering a 
symbiotic 
relationship built on mutual benefit and professionalism.<br/><br/>
Join <span className=' font-bold text-[#8B014A]'>The Brand Bucks</span>, where expertise meets excellence, and together, let's redefine the standards of 
consumer engagement and business success.
      </p>
    </div>
    </div>
    
 <motion.div
  className='md:w-[40%] w-full ml-0 md:ml-3 mt-9'
  initial={{ opacity: 0, scale: 0.8 }}
  whileInView={{ opacity: 1, scale: 1 }}
  transition={{ duration: 0.5 }}
  viewport={{ once: false }}
>
  <img className='rounded-md shadow-lg w-full' src={about} />
</motion.div>

    </div>
    </div>
  )
}

export default AboutUSImg
