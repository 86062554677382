import React from 'react';
import social from '../resources/social.jpg';
import reviewer from '../resources/review.jpeg';
import backgroundImage from '../resources/bg.png'; // Make sure to import your background image
import '../Components/Buttons.css'
import { motion } from 'framer-motion';
import './Articles.css'
import { Link } from 'react-router-dom';

const Articles = () => {
  return (
    <div
      className='w-full bg-newsletter newsletter-background'
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundAttachment: 'fixed' }}
    >
      <div className='content-center w-[90%] mx-auto justify-center text-center pt-4 p-3'>
        <div className='p-3 mt-3 shadow-2xl text-center md:flex flex-col md:flex-row'>
          <div className='md:w-[60%] flex flex-col items-center md:items-start'>
            <p className='text-white font-extrabold md:text-2xl text-xl font-mon text-start'>"Empowering Consumers Through Expert Reviews."</p>
            <p className='text-left text-white mt-5 mb-2 font-medium font-mon'>
              Our expert reviewers meticulously evaluate brands and products, providing comprehensive insights that empower consumers to make informed decisions.
              <br />
            </p>
            <ul className='text-white text-start'>
              <li className='ml-5 text-gray-400 list-disc'>
                Discover the difference with our in-depth reviews, crafted to empower you in navigating the vast landscape of brands and products.
              </li>
              <li className='ml-5 list-disc text-gray-400'>
                Uncover the essence of quality and reliability as our reviewers delve deep into brands and products, equipping you with the knowledge to choose wisely.
              </li>
              <li className='ml-5 list-disc text-gray-400'>
                Navigate the world of consumer choices with confidence, armed with the knowledge gleaned from our thorough evaluations and insights.
              </li>
              <li className='ml-5 list-disc text-gray-400'>
                From the latest trends to timeless classics, our reviews provide the clarity and understanding necessary for consumers to make informed and empowered choices.
              </li>
            </ul>
            <br />
            <div className="flex justify-center items-center mt-4 md:mt-0 w-full">
              <Link to='/bnp'>
                <motion.button
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: false }}
                  className="flex justify-center items-center whitespace-nowrap px-6 py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans overflow-hidden text-base sm:text-lg md:text-xl"
                >
                  <span className="relative z-10">List your Products</span>
                </motion.button>
              </Link>
            </div>
          </div>
          <motion.div
            className='right-4 p-2 md:w-[40%] md:ml-5 mt-5 md:mt-0'
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false }}
          >
            <img className='border-4 rounded-md shadow-xl shadow-custom-light' src={social} alt='Social' id='bdr' />
          </motion.div>
        </div>
        
        {/* Horizontal Rule */}
        <hr className='my-8 border-gray-300' />

        <div className='p-3 shadow-2xl text-center rounded-2xl mt-8 mb-10 md:flex'>
          <motion.div
            className='content-start md:h-[200px] justify-start items-start md:w-[40%] mt-3'
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: false }}
          >
            <img className='border-4 rounded-md' src={reviewer} alt='Reviewer'/>
          </motion.div>
          <div className='ml-5 mt-3 w-full md:w-[60%]'>
            <p className='text-white font-extrabold md:text-2xl text-lg pt-2 font-mon text-start'>"Rewarding The Reviewer's Valuable Contributions"</p>
            <p className='text-left text-white mt-5 mb-2 font-medium font-mon'>
              With our unparalleled review platform, brands experience accelerated growth by leveraging the power of authentic feedback. Meanwhile, our reviewers are rewarded handsomely for their valuable contributions to the review process.
              <br />
            </p>
            <ul className='text-white text-start'>
              <li className='text-gray-400 list-disc ml-5'>
                <span className='font-bold'>Accelerated Growth:</span> Brands experience rapid expansion and success by harnessing the power of genuine reviews, propelling them ahead of the competition.
              </li>
              <li className='list-disc text-gray-400 ml-5'>
                <span className='font-bold'>Authentic Feedback:</span> Our platform prioritizes genuine opinions, ensuring that brands receive honest and insightful feedback from real users.
              </li>
              <li className='list-disc text-gray-400 ml-5'>
                <span className='font-bold'>Rewards for Reviewers:</span> We value the contributions of our reviewers and compensate them generously for their efforts, fostering a mutually beneficial relationship between brands and reviewers.
              </li>
              <li className='list-disc text-gray-400 ml-5'>
                <span className='font-bold'>Invaluable Contributions:</span> Reviewers play a crucial role in the review process, driving the authenticity and credibility of the platform while being recognized and rewarded for their contributions.
              </li>
            </ul>
            <br />
            <div className="flex justify-center items-center mt-4">
              <Link to='/sign-up'>
                <motion.button
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: false }}
                  className="flex justify-center items-center whitespace-nowrap px-6 py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans overflow-hidden text-base sm:text-lg md:text-xl"
                >
                  <span className="relative z-10">Join As Reviewer</span>
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Articles;
