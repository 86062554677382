import React from 'react';
import { motion } from 'framer-motion';
import BNPObject from '../Objects/BNPObject';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';


const BrandsAndProducts = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <div className='relative backgroundBlack bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold md:text-4xl text-2xl text-white pt-12'>
          <p>Review Marketing</p>
        </div>
      </div>
      
      <div className='w-full'>
        <div className='mx-auto md:p-10 p:0'>
          <motion.div
            initial='hidden'
            whileInView='visible'
            variants={itemVariants}
            transition={{ duration: 0.5 }}
            className='pl-4 pr-4 pt-3'
            viewport={{ once: false }}
          >
            <div className='text-start font-semibold sm:text-lg'>
              <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                Welcome to The Brand Bucks, where we bring you a diverse range of brands and products designed to meet your unique needs. Explore our offerings and find the perfect solutions for your business.
              </p>
              <div className='right p-4 w-full'>
      <div className='video-wrapper'>
        <ReactPlayer
          url='https://youtu.be/08gxTCkr9F4'
          width='100%'
          height={400}
          controls={true}
          className='rounded-3xl react-player shadow-lg'
        />
      </div>
    </div>
            </div>
            {BNPObject.map((value, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <div
                  className={`md:flex w-full ${index === BNPObject.length - 1 ? ' w-[100%]' : (index % 2 === 0 ? '' : 'flex-row-reverse justify-start text-start')} items-start mb-4`}
                  style={{ marginRight: index % 2 !== 0 && index !== BNPObject.length - 1 ? '1%' : '0' }}
                >
                  <div className={`text-start ${index % 2 === 0 && index !==BNPObject.length -1 ? '' : ' ml-5'} ${index !== BNPObject.length -1 ? 'md:w-[60%]': ' md:w-[100%] font-bold ml-0 '} `}>
                    <h1 className='font-bold text-[#8B014A] md:text-xl text-xl leading-normal'>{value.title}</h1>
                    <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{value.description}</p>
                  </div>
                  <div className={`right ${index !== BNPObject.length -1 ? '' : ' md:w-[0%]'} md:w-[40%]`}>
                    <img src={value.imgs} alt={value.title}  className='rounded-md shadow-lg'/>
                  </div>
                </div>
              </motion.div>
            ))}
            <Link to='/bnp'>
<div className="flex justify-center items-center">
  <motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
    className="flex justify-center items-center mr-0 whitespace-nowrap px-6 py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#8B014A] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-base sm:text-lg md:text-xl">
    List Your Brands & Products
  </motion.button>
</div>   </Link>  
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default BrandsAndProducts;
