import React, { Fragment, useState } from 'react'
import {
    Accordion,
    AccordionHeader, AccordionBody
} from "@material-tailwind/react"
import AccordionGQObject from '../Objects/AccordionGQObject'

const Icon = ({ id, open}) =>
{
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  )
}
const AccordianGQ = () => {
    const [open, setOpen] = useState(0);
    const [color, setColor] = useState(false);

    const handleOpen = (value) =>
    {
        setOpen(open === value? 0:value);
    }
    const handleColor =(value) =>
    {
      setColor(open === value? " text-[#E600FF]" : ' text-[#15191B]')
    }
  return (
    <Fragment>
    
        {
          AccordionGQObject.map((valuess) =>
          {
            return(
              <Accordion className=' hover:border-[#88014A] hover:border mb-9 shadow-md border-red-800 rounded-xl ' open={open === valuess.id} icon={<Icon id={valuess.id} open={open} />}>
        <AccordionHeader className ={  ' font-mon not-italic sm:font-semibold text-start font-medium sm:p-5 p-4 text-md sm:leading-5 sm:text-[#15191B] text-[#15191B]'   }  onClick={() => handleOpen(valuess.id)}>
        {
          valuess.header
        }
        </AccordionHeader>
        <AccordionBody className=' font-mon not-italic font-medium text-sm leading-7 text-[#15191B] p-5  text-start'>
          {
            valuess.body
          }
        </AccordionBody>
      </Accordion>
            )
          })
        }
      
      
      
    </Fragment>
  )
}


export default AccordianGQ
