import React from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import csd from '../../resources/csd.png'
import webad from '../../resources/webad.jpeg'
import mobilead from '../../resources/mobilead.jpg'
import cloud from '../../resources/cloud.png'
import ai from '../../resources/ai.jpeg'
import cyber from '../../resources/cyber.jpeg'
import itcs from '../../resources/itcs.png'
import si from '../../resources/si.png'
import { Link } from 'react-router-dom';

const ITDevelopmentServices = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const services = [
    {
      img: csd,
      title: "Custom Software Development",
      description: "Create software solutions tailored specifically to your business requirements. Our custom software development services ensure that your unique needs are met with precision and efficiency.",
      points: [
        "Application Development",
        "Enterprise Software Solutions",
        "SaaS Development",
        "Mobile App Development",
        "API Development and Integration",
      ],
    },
    {
      img: webad,
      title: "Web Development",
      description: "Enhance your online presence with our comprehensive web development services. We build responsive, user-friendly websites that drive engagement and conversions.",
      points: [
        "Website Design and Development",
        "E-commerce Development",
        "CMS Development",
        "Progressive Web Apps (PWA)",
        "Website Maintenance and Support",
      ],
    },
    {
      img: mobilead,
      title: "Mobile App Development",
      description: "Reach your customers on the go with our mobile app development services. We create high-performance, feature-rich mobile applications for iOS and Android platforms.",
      points: [
        "Native App Development",
        "Hybrid App Development",
        "Cross-Platform App Development",
        "App UI/UX Design",
        "App Testing and QA",
      ],
    },
    {
      img: cloud,
      title: "Cloud Solutions",
      description: "Leverage the power of the cloud with our comprehensive cloud solutions. We provide end-to-end cloud services to enhance your business scalability and efficiency.",
      points: [
        "Cloud Strategy and Consulting",
        "Cloud Migration",
        "Cloud Infrastructure Management",
        "Cloud Application Development",
        "DevOps and Automation",
      ],
    },
    {
      img: ai,
      title: "Data Analytics and Business Intelligence",
      description: "Transform your data into actionable insights with our data analytics and business intelligence services. We help you make data-driven decisions to drive business growth.",
      points: [
        "Data Warehousing",
        "Data Mining and Analysis",
        "Business Intelligence Solutions",
        "Big Data Solutions",
        "Predictive Analytics",
      ],
    },
    {
      img: cyber,
      title: "Cybersecurity Solutions",
      description: "Protect your business from digital threats with our robust cybersecurity solutions. We provide comprehensive security services to safeguard your data and IT infrastructure.",
      points: [
        "Security Assessment and Audits",
        "Network Security",
        "Endpoint Security",
        "Identity and Access Management",
        "Incident Response and Management",
      ],
    },
    {
      img: itcs,
      title: "IT Consulting and Strategy",
      description: "Optimize your IT infrastructure with our expert IT consulting and strategy services. We provide strategic guidance to help you achieve your business objectives.",
      points: [
        "IT Strategy Development",
        "IT Infrastructure Assessment",
        "Technology Roadmap",
        "IT Cost Optimization",
        "Digital Transformation Consulting",
      ],
    },
    {
      img: si,
      title: "System Integration",
      description: "Ensure seamless operation across your business systems with our system integration services. We integrate disparate systems to enhance workflow efficiency and data consistency.",
      points: [
        "Enterprise Application Integration",
        "API Management",
        "Middleware Solutions",
        "Data Integration",
        "System Migration and Upgrades",
      ],
    },
  ];

  const benefits = [
    {
      title: 'Expertise and Experience',
      description: 'Our team of IT professionals has extensive experience in delivering innovative technology solutions across various industries.',
    },
    {
      title: 'Customized Solutions',
      description: 'We understand that every business has unique needs. Our solutions are tailored to meet your specific requirements and goals.',
    },
    {
      title: 'Cutting-edge Technology',
      description: 'We utilize the latest technologies and industry best practices to ensure high-quality service delivery.',
    },
    {
      title: 'Reliable Support',
      description: 'We provide ongoing support and maintenance to ensure your IT systems run smoothly and efficiently.',
    },
    {
      title: 'Security and Compliance',
      description: 'We prioritize the security of your data and ensure compliance with industry standards and regulations.',
    },
  ];

  return (
    <div>
      <div className='relative backgroundBlack bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold md:text-4xl text-2xl text-white pt-12'>
          <p>IT Development Services</p>
        </div>
      </div>

      <div className='w-full'>
        <div className='mx-auto md:p-10 p:1 '>
          <motion.div
            initial='hidden'
            whileInView='visible'
            variants={itemVariants}
            transition={{ duration: 0.5 }}
            className='pl-4 pr-4 pt-3'
            viewport={{ once: false }}>
            <div className='text-start font-semibold text-lg'>
              <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                Welcome to The Brand Bucks, your trusted partner in IT Development Services. We provide comprehensive and customizable solutions designed to help businesses streamline operations, reduce costs, and enhance productivity. Our services cover a broad spectrum of IT needs, ensuring that you can focus on your core competencies while we manage the rest.
              </p>
              <div className='right p-4 w-full'>
      <div className='video-wrapper'>
        <ReactPlayer
          url='https://youtu.be/I6F3XUxzSGM?si=H1pfrMoDkp-bdyJx'
          width='100%'
          height={400}
          controls={true}
          className='rounded-3xl react-player shadow-lg'
        />
      </div>
    </div>
            </div>
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <div
                  className={`md:flex w-full ${index % 2 === 0 ? '' : 'flex-row-reverse justify-start text-start'} items-start mb-5`}
                  style={{ marginRight: index % 2 !== 0 ? '1%' : '0' }}
                >
                  <div className={`text-start ${index % 2 === 0 ? '' : ' ml-5'} md:w-[60%]`}>
                    <h1 className='font-bold text-[#8B014A] md:text-xl text-xl leading-normal'>{service.title}</h1>
                    <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{service.description}</p>
                    <ul className='list-disc pl-5'>
                      {service.points.map((point, i) => (
                        <li key={i} className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='right md:w-[40%]'>
                    <img className='rounded-md shadow-lg' src={service.img} alt={service.description} key={service.index}/>
                  </div>
                </div>
              </motion.div>
            ))}
       
<div className="flex justify-center items-center">
<Link to='/contact-us'>
<motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
    className="flex justify-center items-center text-center whitespace-nowrap px-6 py-3 sm:px-6 sm:py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#8B014A] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-sm sm:text-base md:text-lg">

List Your IT Development Needs
</motion.button> </Link>  </div>     
          </motion.div>
        </div>
      </div>

      <div className='w-full mt-10'>
        <div className='mx-auto w-[90%] text-start p-3 rounded-md shadow-lg'>
          <h2 className='font-mon not-italic font-bold md:text-3xl text-2xl text-[#15191B]'>
            Why Choose <span className=' text-[#88014A]'>The Brand Bucks?</span>
          </h2>
          <div className='mt-5'>
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <h3 className='font-semibold md:text-lg text-base leading-normal text-[#88014A]'>{benefit.title}</h3>
                <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITDevelopmentServices;
