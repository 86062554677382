// src/data.js
export const ServiceObject = [
    {
      title: "Retail and E-commerce",
      points: [
        "Online Retailers",
        "Brick-and-Mortar Stores",
        "Omnichannel Retail",
        "Marketplaces",
        "Supply Chain Management",
      ],
    },
    {
      title: "Healthcare",
      points: [
        "Hospitals and Clinics",
        "Health Insurance",
        "Medical Devices",
        "Telemedicine",
        "Pharmaceuticals",
        "Biotechnology",
      ],
    },
    {
      title: "Finance and Banking",
      points: [
        "Commercial Banks",
        "Investment Banks",
        "Insurance Companies",
        "Credit Unions",
        "Fintech",
        "Asset Management",
      ],
    },
    {
      title: "Telecommunications",
      points: [
        "Mobile Network Operators",
        "Internet Service Providers",
        "Satellite Communications",
        "Cable TV Providers",
        "Telecom Equipment",
      ],
    },
    {
      title: "Technology",
      points: [
        "Software Development",
        "Hardware Manufacturing",
        "IT Services",
        "Cloud Computing",
        "Cybersecurity",
        "Artificial Intelligence",
      ],
    },
    {
      title: "Travel and Hospitality",
      points: [
        "Airlines",
        "Hotels and Resorts",
        "Travel Agencies",
        "Cruise Lines",
        "Tourism Boards",
      ],
    },
    {
      title: "Manufacturing",
      points: [
        "Automotive Manufacturing",
        "Electronics Manufacturing",
        "Industrial Equipment",
        "Consumer Goods Manufacturing",
        "Aerospace Manufacturing",
      ],
    },
    {
      title: "Education",
      points: [
        "K-12 Schools",
        "Higher Education Institutions",
        "Online Learning Platforms",
        "Educational Technology",
        "Vocational Training",
      ],
    },
    {
      title: "Real Estate",
      points: [
        "Residential Real Estate",
        "Commercial Real Estate",
        "Property Management",
        "Real Estate Development",
        "Real Estate Investment",
      ],
    },
    {
      title: "Logistics and Transportation",
      points: [
        "Freight and Cargo",
        "Shipping and Maritime",
        "Rail Transport",
        "Trucking and Haulage",
        "Warehousing and Distribution",
      ],
    },
    {
      title: "Media and Entertainment",
      points: [
        "Film and Television",
        "Music Industry",
        "Publishing",
        "Gaming",
        "Broadcasting",
      ],
    },
    {
      title: "Legal Services",
      points: [
        "Law Firms",
        "Corporate Legal Departments",
        "Legal Technology",
        "Litigation Support",
        "Intellectual Property Services",
      ],
    },
    {
      title: "Non-Profit Organizations",
      points: [
        "Charitable Organizations",
        "Foundations",
        "NGOs",
        "Social Enterprises",
        "Community Services",
      ],
    },
    {
      title: "Energy and Utilities",
      points: [
        "Oil and Gas",
        "Renewable Energy",
        "Electricity Providers",
        "Water Utilities",
        "Waste Management",
      ],
    },
    {
      title: "Agriculture",
      points: [
        "Crop Production",
        "Livestock Farming",
        "Agribusiness",
        "Agricultural Technology",
        "Forestry",
      ],
    },
    {
      title: "Automotive",
      points: [
        "Vehicle Manufacturing",
        "Auto Dealerships",
        "Auto Parts Suppliers",
        "Electric Vehicles",
        "Autonomous Vehicles",
      ],
    },
    {
      title: "Pharmaceuticals",
      points: [
        "Drug Manufacturing",
        "Biotechnology Firms",
        "Clinical Research",
        "Generic Pharmaceuticals",
        "Over-the-Counter Medicines",
      ],
    },
    {
      title: "Construction",
      points: [
        "Residential Construction",
        "Commercial Construction",
        "Infrastructure Development",
        "Construction Materials",
        "Engineering Services",
      ],
    },
    {
      title: "Consumer Goods",
      points: [
        "Food and Beverage",
        "Personal Care Products",
        "Household Goods",
        "Apparel and Footwear",
        "Electronics",
      ],
    },
    {
      title: "Public Sector and Government",
      points: [
        "Federal Government",
        "State and Local Government",
        "Public Administration",
        "Defense and Military",
        "Public Health Services",
      ],
    },
  ];


  