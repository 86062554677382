import React from 'react'
import blog3 from '../../resources/tbbdir.jpg'
const EcommerceBrands = () => {
  return (
    <div className=' w-full h-full'>
    <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B] md:p-10 p-2 '>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white pt-10 pb-2'>Our Blogs</p>
      </div>
      <div className=' w-[50%] mx-auto mt-10'>
        <img className=' mx-auto rounded-lg' src={blog3}/>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>As the <span className=' text-[#8B014A] font-bold'>Director</span> of our esteemed company, it is my privilege to extend a warm welcome to you, whether you are a seasoned professional or a newcomer embarking on your entrepreneurial journey. At our company, we are dedicated to supporting individuals at every stage of their careers and ensuring that each person achieves their goals with us.
<br/><br/>
One of my core beliefs as a Director is the importance of nurturing talent and encouraging newcomers to strive for a better future. I am committed to providing guidance, mentorship, and resources to help individuals unlock their full potential and seize opportunities for growth and success. Whether you are just starting out or looking to take your career to the next level, you can count on me and my team to be there every step of the way.
<br/><br/>
I firmly believe that success is not just about reaching your destination, but also about the journey along the way. That's why I am dedicated to creating an environment where every person feels valued, empowered, and supported in pursuing their aspirations. Whether it's through training programs, professional development opportunities, or simply a listening ear, I am committed to ensuring that every single person has the tools and support they need to thrive.
<br/><br/>
At our company, we understand that success looks different for everyone. That's why we take a personalized approach to helping individuals achieve their aims, tailoring our support and resources to meet their unique needs and aspirations. Whether you dream of launching your own business, climbing the corporate ladder, or making a difference in your community, we are here to help you turn your dreams into reality.
<br/><br/>
As a Director, my door is always open, and my commitment to your success is unwavering. Together, let's embark on this journey towards a brighter future, where every person has the opportunity to achieve their aims and fulfill their potential. With dedication, perseverance, and support, there is no limit to what we can accomplish together.
<br/><br/>
Thank you for choosing to partner with us on this journey. Your success is our success, and I am honored to be a part of your story.
</p>

        </div>
      </div>
      </div>
  )
}

export default EcommerceBrands
