"use client";

import React from "react";
import { Link } from "react-router-dom";
import img1 from './images/banner-2/banner-2-shape1.png';
import img2 from './images/banner-2/banner-2-shape2.png';
import img3 from './images/banner-2/banner-2-shape3.png';
import img4 from './images/banner-2/banner-2-shape4.png';
import img5 from './images/banner-2/banner-2-shape5.png';
import img6 from './images/banner-2/banner-2-shape6.png';
import img7 from './images/banner-2/banner-2-shape7.png';
import img8 from './images/banner-2/banner-2-shape8.png';
import img9 from './images/banner-image.png';
import img10 from './images/shape/shape2.svg';
import img11 from './images/shape/shape3.png';
import img12 from './images/shape/shape5.svg';
import img13 from './images/shape/dot1.png';
import img14 from './images/shape/dot3.png';
import { motion } from 'framer-motion';
import './MainBanner.css';
import playstore from '../resources/ICC.png';
import appstore from '../resources/tise.png';

const MainBanner = () => {
  return (
    <>
      <div className="banner-section">
        <div className="container">
          <div className="row sm:flex">
            <div className="text-start ml-3 sm:w-full w-full">
              <div className="banner-content">
              <h1
  data-aos="fade-in"
  data-aos-duration="1000"
  data-aos-delay="200"
  className="text-start w-full mr-4 md:text-6xl text-3xl md:mr-0 font-bold"
>
  The Brand Bucks
</h1>
<p
  className="text-start text-[#8B014A] not-italic md:font-bold font-bold md:text-2xl text-2xl leading-lineHeight25 w-full"
  data-aos="fade-in"
  data-aos-duration="1000"
  data-aos-delay="300"
>
  "Your Partner in Growth and Efficiency"
</p>
<p className="text-start font-mon not-italic mt-3 text-lg font-semibold leading-7 text-[#626262]">
  Crafting Brands, Amplifying Revenue, and Inspiring Rave Reviews <br />
  Elevating Your Business Beyond Expectations!
</p>

                <div className="relative h-auto flex md:w-full mt-2">
                <h2 className="text-black font-bold text-2xl sm:text-2xl md:text-3xl lg:text-3xl">Affiliations</h2>

                </div>
                <div className="content-start flex">
                  <Link target="_blank" rel="noopener noreferrer" to="https://iccwbo.org/">
                    <img className="cursor-pointer w-48 h-28 p-2 rounded-lg" src={playstore} alt="playstore" />
                  </Link>
                  <div className="px-4 pt-5 ml-3">
                    <Link target="_blank" rel="noopener noreferrer" to="https://tisegroup.com/">
                      <img className="cursor-pointer w-40 h-20 p-2 rounded-lg" src={appstore} alt="appstore" />
                    </Link>
                  </div>
                </div>
                <div
                  className="btn-box"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >

      <Link to='/contact-us'><div className="flex justify-center md:justify-start items-center">
            <motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
      className="flex justify-center items-center ml-0 md:ml-3 md:mr-0 mr-8 whitespace-nowrap px-10 py-3 bg-[#340941] hover:bg-[#88014A] rounded-3xl text-white relative font-semibold font-sans overflow-hidden text-base sm:text-lg md:text-xl"
    >
      <span className="relative z-10">Contact Us</span>
   
    </motion.button>



</div></Link>  
                </div>
              </div>
            </div>

            <div className="sm:w-1/2 w-full">
              <div className="banner-image">
                <img src={img1} alt="shape 1" width={120} height={120} />
                <img
                  src={img2}
                  alt="shape 2"
                  width={180}
                  height={132}
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="50"
                />
                <img
                  src={img3}
                  alt="shape 3"
                  width={249}
                  height={239}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                />
                <img
                  src={img4}
                  alt="shape 4"
                  width={180}
                  height={114}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="150"
                />
                <img
                  src={img5}
                  alt="shape 5"
                  width={279}
                  height={233}
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                />
                <img
                  src={img6}
                  alt="shape 6"
                  width={180}
                  height={170}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="250"
                />
                <img
                  src={img7}
                  alt="shape 7"
                  width={190}
                  height={309}
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                />
                <img
                  src={img8}
                  alt="shape 8"
                  width={390}
                  height={188}
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                />
                <img
                  src={img9}
                  alt="banner"
                  width={924}
                  height={530}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="450"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Shape Images */}
        <div className="shape-img2">
          <img src={img10} alt="shape 2" width={21} height={20} />
        </div>
        <div className="shape-img3">
          <img src={img11} alt="shape 3" width={202} height={202} />
        </div>
        <div className="shape-img5">
          <img src={img12} alt="shape 5" width={22} height={22} />
        </div>
        <div className="dot-shape1">
          <img src={img13} alt="dot 1" width={15} height={15} />
        </div>
        <div className="dot-shape2">
          <img src={img14} alt="dot 2" width={12} height={11} />
        </div>
      </div>
    </>
  );
};

export default MainBanner;
