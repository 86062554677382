import React from 'react';

const Card = ({ title, points }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 m-1 w-full">
      <h2 className="text-xl text-start text-[#88014A] font-bold mb-2">{title}</h2>
      <ul className="list-disc list-inside text-gray-700 mr-0">
        {points.map((point, index) => (
          <li
            key={index}
            className="w-full list-item-hover font-mon font-semibold text-start cursor-pointer transition-all duration-300 ease-in-out pl-0"
          >
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Card;
