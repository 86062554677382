// RegistrationModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './AboutUS.css'

const SignUpDialog = ({ isOpen, onClose }) => {
    
 
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{ zIndex:3 }} 
      className="modal fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50"
      overlayClassName="overlay"
    >
      <motion.div
        className="bg-white p-6 p-4 rounded-lg shadow-lg w-full max-w-md md:mt-5 mt-16"
       id='hig'
        initial={{ scale: 0.3, opacity: 0 }}
        animate={{ scale: 1.2, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
      <button
          className="absolute top-1 text-2xl hover:text-3xl ease-out hover:animate-ping right-5 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          &times;
        </button>
      <div className="flex flex-col space-y-2">
          <Link to='/sign-up' className='hello mt-3'><div
          onClick={onClose}
            className="p-4 cursor-pointer"
          >
            <h2 className="text-lg font-semibold">Reviewers</h2>
            <p className="text-gray-600">Sign up as a reviewer and start reviewing products.</p>
          </div></Link>


          <Link to='/bnp' className='hello'><div
          onClick={onClose}
            className="p-4 cursor-pointer"
          >
            <h2 className="text-lg font-semibold">Brands & Products</h2>
            <p className="text-gray-600">Provide information about your company and products.</p>
          </div></Link>
        </div>
      </motion.div>
    </Modal>
  );
};

export default SignUpDialog;
