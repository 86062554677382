import React from 'react'
import AccordianGQ from './AccordianGQ'
import './AboutUS.css'

const FAQS = () => {
  return (
    <div className=' w-full h-full'>
     <div className=' md:p-12 p-3 backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background'>
      <div className=' font-mon not-italic font-bold text-4xl text-white pt-12'>
        <p>FAQs</p>
      </div>
    </div>
      <div className='whatis w-[80%] mx-auto'>
      <div className=' text-start font-mon not-italic 
      font-extrabold md:text-4xl 
      leading-lineHeight44 text-[#15191B]'>
        <p className=' md:text-3xl text-[#88014A] text-lg mt-3 text-center'>Frequently Asked Questions</p>
      </div>
      </div>
      <div className=' content-center  md:mx-auto md:w-[87%] w-[100%] w-full p-3'>
        <AccordianGQ/>
        
      </div>
    </div>
  )
}

export default FAQS
