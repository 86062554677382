import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { motion } from 'framer-motion';
import './Home.css';
import { ServiceObject } from './OurService/ServiceObject';

const SellerSignUp = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    phone: '',
    productDetails: '',
    industry: '',
    subIndustry: '',
    customIndustry: '',
  });

  const [result, setResult] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIndustryChange = (e) => {
    const selectedIndustry = e.target.value;
    setFormData({
      ...formData,
      industry: selectedIndustry,
      subIndustry: '', // Reset subIndustry when industry changes
      customIndustry: '',
    });
  };

  const handleSubIndustryChange = (e) => {
    setFormData({
      ...formData,
      subIndustry: e.target.value,
    });
  };

  const handleCustomIndustryChange = (e) => {
    setFormData({
      ...formData,
      customIndustry: e.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
    
    const formDataObj = new FormData(event.target);
    formDataObj.append("access_key", "09299f6c-2cba-48d5-9c52-afeaa7f70412");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataObj,
      });

      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("Submission failed, please try again.");
    }
  };

  // Get sub-industries for the selected industry
  const selectedIndustry = ServiceObject.find(service => service.title === formData.industry);
  const subIndustryOptions = selectedIndustry ? selectedIndustry.points : [];

  return (
    <div className="content-center justify-center w-full">
      <div className='backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold text-4xl text-white pt-12'>
          <p>List Your Brands or Products</p>
        </div>
      </div>
      <motion.div 
        className="w-full md:flex" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}
      >
        <div className="mx-auto border-2 m-5 rounded-lg shadow-xl md:w-[70%] form-input">
          <div className="p-4">
            <form onSubmit={onSubmit}>
              <div className="md:flex mt-2">
                <div className="md:w-1/2 mb-4">
                  <div className="relative">
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder=" "
                      value={formData.companyName}
                      onChange={handleInputChange}
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="companyName"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Company Name <span className="text-red-500">*</span>
                    </label>
                  </div>
                </div>
                <div className="md:w-1/2 md:ml-3 mb-4">
                  <div className="relative">
                    <input
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      title="Please enter a valid email address"
                      type="email"
                      id="email"
                      name="email"
                      placeholder=" "
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="email"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="md:ml-0 mt-2 md:flex space-x-0 md:space-x-2">
              <div className="md:w-1/2 mb-4 pl-0">
  <div className="relative">
    <input
      type="tel"
      id="phone"
      name="phone"
      placeholder=" "
      value={formData.phone}
      onChange={handleInputChange}
      required
      pattern="[0-9]*" // Restricts input to numeric characters only
      title="Please enter a valid phone number with numeric characters only"
      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
    />
    <label
      htmlFor="phone"
      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
    >
      Phone Number <span className="text-red-500">*</span>
    </label>
  </div>
</div>

                <div className="md:w-1/2 ml-3 mb-4">
                  <div className="relative">
                    <select
                      id="industry"
                      name="industry"
                      value={formData.industry}
                      onChange={handleIndustryChange}
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    >
                      <option value="">Select an Industry</option>
                      {ServiceObject.map((service) => (
                        <option key={service.title} value={service.title}>
                          {service.title}
                        </option>
                      ))}
                      <option value="Other">Other</option>
                    </select>
                    <label
                      htmlFor="industry"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Select an Industry <span className="text-red-500">*</span>
                    </label>
                  </div>
                </div>
              </div>
              {formData.industry && formData.industry !== 'Other' && (
                <div className="md:w-full md:mr-3 mr-3 mb-4 mt-6">
                  <div className="relative">
                    <select
                      id="subIndustry"
                      name="subIndustry"
                      value={formData.subIndustry}
                      onChange={handleSubIndustryChange}
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    >
                      <option value="">Select a Sub-Industry</option>
                      {subIndustryOptions.map((sub) => (
                        <option key={sub} value={sub}>
                          {sub}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="subIndustry"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Sub-Industry
                    </label>
                  </div>
                </div>
              )}
              {formData.industry === 'Other' && (
                <div className="md:w-full md:mr-3 mr-3 mb-4 mt-6">
                  <div className="relative">
                    <input
                      type="text"
                      id="customIndustry"
                      name="customIndustry"
                      placeholder=" "
                      value={formData.customIndustry}
                      onChange={handleCustomIndustryChange}
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="customIndustry"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Custom Industry
                    </label>
                  </div>
                </div>
              )}
              <div className="md:w-full md:mr-3 mr-3 mb-4 mt-6">
                <div className="relative">
                  <textarea
                    id="productDetails"
                    name="productDetails"
                    placeholder=" "
                    value={formData.productDetails}
                    onChange={handleInputChange}
                    required
                    className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    rows="4"
                  />
                  <label
                    htmlFor="productDetails"
                    className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                  >
                    Product Details <span className="text-red-500">*</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="md:w-[15%] w-[30%] text-white bg-[#88014A] hover:bg-[#340941] rounded-3xl py-2 px-4 mt-4 transition duration-300 ease-in-out"
                >
                  Submit
                </button>
              </div>
              <div className="text-center mt-4 text-[#8B014A]">
                {result}
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SellerSignUp;
