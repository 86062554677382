import React, { useState, useEffect } from 'react';
import logo from '../resources/brand.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import '../Components/Buttons.css';
import SignUpDialog from '../Components/SignUpDialog';
import './AboutUS.css';

const navItems = [
  { label: 'Home', path: '/' },
  { label: 'About Us', path: '/about-us' },
  {
    label: 'Our Services',
    dropdown: true,
    subItems: [
      { label: 'Review Marketing', path: '/brands-and-products' },
      { label: '<b>B</b>usiness <b>P</b>rocess <b>O</b>utsource', path: '/bpo' },
      { label: '<b>B</b>usiness <b>D</b>evelopment', path: '/bds' },
      { label: '<b>IT</b> Development Service', path: '/it&ds' },
      { label: 'Marketing & Advertising', path: '/m&a' }
    ]
  },
  { label: 'Reviewers', path: '/reviewers' },
  { label: 'FAQs', path: '/faqs' },
  { label: 'Contact Us', path: '/contact-us' }
];

const Navbar = () => {
  const [navBackground, setNavBackground] = useState('bg-transparent');
  const [textColor, setTextColor] = useState('text-black');
  const [open, setOpen] = useState(false);//haburger
  const [activeMenu, setActiveMenu] = useState(null);
  const [showServices, setShowServices] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setNavBackground('bg-white shadow-md');
      setTextColor('text-black');
    } else {
      if (location.pathname === '/') {
        setNavBackground('bg-transparent');
        setTextColor('text-black');
      } else {
        setNavBackground('bg-transparent');
        setTextColor('text-white');
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setTextColor('text-black');
    } else {
      setTextColor('text-white');
    }
    // Apply the scroll listener for all pages
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  const handleMenuHover = (index) => {
    setActiveMenu(index);
    if (index === 2) { // 2 is the index of 'Our Services'
      setShowServices(true);
    } else {
      setShowServices(false);
    }
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  const toggleModal = () => {
    setShowDialog(!showDialog);
    if(open==true){
      setOpen(!open);
    }
    
  };

  // Function to check if the current path matches any sub-paths under 'Our Services'
  const isOurServicesActive = () => {
    return navItems[2].subItems.some(subItem => location.pathname.startsWith(subItem.path));
  };

  return (
    <>
      <nav className={`fixed w-full ${navBackground} font-mon font-semibold transition-all z-50 duration-300 ease-in-out`}>
        <div className="max-w-8xl mx-auto px-4 sm:px-2 lg:px-9">
          <div className="flex justify-between items-center sm:py-4 py-2">
            <div className="flex items-center">
              <img className="sm:w-28 w-16" src={logo} alt="Logo" />
            </div>
            <div className={`hidden md:flex space-x-4 items-center ${textColor}`}>
              {navItems.map((item, index) => (
                item.dropdown ? (
                  <div
                    key={index}
                    className="relative group"
                    onMouseEnter={() => handleMenuHover(index)}
                    onMouseLeave={() => handleMenuHover(null)}
                  >
                    <NavLink
                      to={item.subItems[0].path}
                      className={`flex items-center focus:outline-none ${isOurServicesActive() ? 'text-[#88014A]' : 'hover:text-[#88014A]'}`}
                    >
                      {item.label}
                      <svg className="ml-1 h-5 w-5 transform transition-transform duration-200 group-hover:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </NavLink>
                    <div className={`absolute bg-white rounded-md items-center text-start w-72 ${showServices && activeMenu === index ? 'block' : 'hidden'}`}>
                      {item.subItems.map((subItem, subIndex) => (
                        <NavLink key={subIndex} to={subItem.path} className="block px-4 py-2 text-gray-900 hover:bg-gray-200" dangerouslySetInnerHTML={{ __html: subItem.label }}></NavLink>
                      ))}
                    </div>
                  </div>
                ) : (
                  <NavLink id='NavHover' key={index} to={item.path} className={({ isActive }) => 'text-[#8B014A]' + (isActive ? ' text-[#8B014A]' : 'text-[#15191B]')}>
                    {item.label}
                  </NavLink>
                )
              ))}
            </div>
            <div className="hidden md:flex items-center space-x-4">
              {/* <button className={`px-4 golden-button py-2 border ${textColor} border-gray-900 rounded hover:bg-gray-200`}>Login</button> */}
              <button className="px-4 golden-button bg-gray-900 text-white rounded hover:bg-gray-700" onClick={toggleModal}>Sign Up</button>
            </div>
            <div className="md:hidden flex items-center">
            {/*hamburger*/}
              <button className={`focus:outline-none ${textColor}`} onClick={toggleMenu}>
                {open ? (
                  <svg className="h-6 w-6 text-black stroke-current" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={`md:hidden fixed top-0 right-0 w-3/4 h-full bg-white z-50 transform transition-transform duration-300 ease-in-out ${open ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="flex justify-between items-center py-2 px-4">
            <div className=" flex items-center">
              <img className="w-24" src={logo} alt="Logo" />
            </div>
            <button className={`focus:outline-none ${textColor}`} onClick={toggleMenu}>
              <svg className="h-6 w-6 text-black stroke-current" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-col space-y-3">
            {navItems.map((item, index) => (
              item.dropdown ? (
                <div key={index} className="relative group">
                  <div className='flex'>
                    <button
                      className={`flex  items-center text-center mx-auto py- focus:outline-none ${activeMenu === index ? 'text-[#88014A]' : 'hover:text-[#88014A]'}`}
                      onMouseEnter={() => handleMenuHover(index)}
                      onMouseLeave={() => handleMenuHover(null)}
                    >
                      <span>{item.label}</span>
                      <svg className="ml-1 h-5 w-5 transform transition-transform duration-300 group-hover:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </div>
                  <div className={`bg-white shadow-lg rounded-md mt-1 w-full ${index === 2 && showServices ? 'block' : 'hidden'}`}>
                    {item.subItems.map((subItem, subIndex) => (
                      <Link onClick={toggleMenu} key={subIndex} to={subItem.path} className="block px-4 py-2 text-gray-900 hover:bg-gray-200" dangerouslySetInnerHTML={{ __html: subItem.label }}></Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link onClick={toggleMenu} key={index} to={item.path} className={`block px-4 py-2 text-gray-900 hover:bg-gray-200 ${activeMenu === index ? 'text-[#88014A] font-semibold' : ''}`}>{item.label}</Link>
              )
            ))}
            <div className="flex flex-col space-y-2 px-4 py-2">
              {/* <button className="px-4 golden-button py-2 border border-gray-900 text-gray-900 rounded hover:bg-gray-200">Login</button> */}
              <button className="px-4 py-2 golden-button bg-gray-900 text-white rounded hover:bg-gray-700" onClick={toggleModal}>Sign Up</button>
            </div>
          </div>
        </div>
      </nav>
      <SignUpDialog isOpen={showDialog} onClose={toggleModal} />
    </>
  );
};

export default Navbar;
