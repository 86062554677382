import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import AboutUS from './AboutUS'
import ContactUS from './ContactUS'
import FAQS from './FAQS'
import SellerSignUp from './SellerSignup'
import BecomeADriver from './BecomeADriver'
import ReturnPolicy from './ReturnPolicy'
import MerchantSalesChannel from './MerchantSalesChannel'
import TrackingYourOrder from './TrackingYourOrder'
import TermsAndConditions from './TermsAndConditions'
import PrivacyAndCookiePolicy from './PrivacyAndCookiePolicy'
import ElevenTopTipBlog from './OurBlogs/ElevenTopTipBlog'
import BrandsImproving from './OurBlogs/BrandsImproving'
import EcommerceBrands from './OurBlogs/EcommerceBrands'
import BrandsAndProducts from './BrandsAndProducts'
import Reviewers from './Reviewers'
import BPOPage from './OurService/BPOPage'
import BDSPage from './OurService/BDSPage'
import ITDevelopmentServices from './OurService/ITDevelopmentServices'
import AdvertisingMarketingServices from './OurService/AdvertisingMarketingServices'
const Routing = () => {
  return (
    <Routes>
    <Route path='/privacy-and-cookie-policy' element={<PrivacyAndCookiePolicy/>}/>
    <Route path='/t-and-cs' element={<TermsAndConditions/>}/>
    <Route path='/tracking-your-order' element={<TrackingYourOrder/>}/>
    <Route path='/merchant-sales-channel' element={<MerchantSalesChannel/>}/>
    <Route path='/return-policy' element={<ReturnPolicy/>}/>
    <Route path='/ceo' element={<ElevenTopTipBlog/>}/> 
    <Route path='/founder' element={<BrandsImproving/>}/>
    <Route path='/director' element={<EcommerceBrands/>}/>
      <Route path='/' element={<Home/>}/>
      <Route path='about-us' element={<AboutUS/>}/>
      <Route path='/contact-us' element={<ContactUS/>}/>
      <Route path='faqs' element={<FAQS/>}/>
      <Route path='/bnp' element={<SellerSignUp/>}/>
      <Route path='/sign-up' element={<BecomeADriver/>}/>
      <Route path='/brands-and-products' element={<BrandsAndProducts/>}/>
      <Route path='/reviewers' element={<Reviewers/>}/>
      <Route path='/bpo' element={<BPOPage/>}/>
      <Route path='/bds' element={<BDSPage/>}/>
      <Route path='/it&ds' element={<ITDevelopmentServices/>}/>
      <Route path='/m&a' element={<AdvertisingMarketingServices/>}/>
    </Routes>
  )
}

export default Routing
