import React from 'react';

const WhyChooseUs = () => {
  const points = [
    {
      title: "Cost Efficiency",
      description: "Leverage our scalable solutions to reduce operational costs without compromising on quality."
    },
    {
      title: "Expertise",
      description: "Benefit from our team's extensive experience across various business functions."
    },
    {
      title: "Focus on Core Activities",
      description: "Free up your resources to concentrate on strategic initiatives and business growth."
    },
    {
      title: "Quality and Compliance",
      description: "Our commitment to excellence ensures adherence to the highest standards of quality and regulatory compliance."
    },
    {
      title: "Customized Solutions",
      description: "We tailor our services to meet your specific business needs and objectives."
    },
    {
        title: 'Innovative Technology',
        description: 'Stay ahead of the curve with our cutting-edge technology solutions designed to drive efficiency and foster innovation in your business operations.'
    }
  ];

  return (
    <div className="container mx-auto p-6">
      <h2 className="font-mon not-italic font-extrabold text-2xl md:text-4xl text-center animate__animated animate__fadeInDown">Why Choose <span className=' text-[#88014A]'> The Brand Bucks?</span></h2>
      <div className="grid grid-cols-1 md:grid-cols-3 mx-auto gap-0">
        {points.map((point, index) => (
          <div key={index} className="bg-white h-[217px] shadow-lg rounded-lg md:pt-10 pt-1 pl-2 m-3 transition-transform transform hover:scale-105">
            <h3 className="md:text-2xl text-xl font-semibold text-left text-[#88014A] mb-1">{point.title}</h3>
            <p className="text-gray-700 font-semibold text-left">{point.description}</p>
          </div>
        ))}




      </div>
    </div>



  );
};

export default WhyChooseUs;
