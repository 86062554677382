import React from 'react'
import './NewsLetter.css'
import group from '../resources/group6.png'
import backgroundImage from '../resources/maskgroup.png'; // Make sure to import your background image
import { Link } from 'react-router-dom';


const NewsLetter = () => {
    return (
        <div
         className=' bg-[#15191B] bg-cover relative w-[100%] h-[100%]  md:flex bg-newsletter newsletter-background'
         style={{ backgroundImage: `url(${backgroundImage})`, backgroundAttachment: 'fixed' }}
        >
            {/* <div className=' left-div relative md:content-center md:items-center md:justify-center md:text-center md:w-1/2 w-full'>
               <div className=' md:absolute p-10 left-0 right-0 mt-20 content-center text-center'>
               <img className='mx-auto' src={group}/>
               </div>
            </div> */}
            <div className=' mx-auto md:w-1/2 md:mt-12 w-full right-div'>
            <div className=' md:w-[60%] w-[80%] pb-10 md:content-center md:my-32 my-auto h-auto mx-auto'>
                <div className='newsletter font-mon text-white leading-lineHeight44  font-extrabold text-3xl'>
                    <p>Subscribe to <span className=' text-[#8B014A]'>Newsletter</span></p>
                </div>
                <div className=' text-white text-base not-italic leading-5 font-medium mt-3 font-mon'>
                    <p>Get the latest offers and updates happening near you.</p>
                </div>
                <div className=' mt-4'>
                    <form className=' w-full'>
                        
                        <div className="relative content-center">
                            <input type="search" id="default-search" 
                            className="block w-full p-4
                             text-gray-900 border hover:border-[#8B014A] 
                             rounded-lg bg-gray-50 
                             focus:border-[#8B014A]                                dark:text-black dark:focus:ring-[#8B014A] font-mon font-medium text-sm leading-4" 
                               placeholder="Enter your email address" 
                               required/>
                            <button type="submit" className="
                            text-white absolute right-2 bottom-2 
                            bg-[#88014A] hover:bg-[#340941] rounded-3xl
                            focus:ring-4 focus:outline-none 
                            focus:ring-blue-300 font-medium rounded-md 
                            text-sm px-4 py-2 hover:text-white ">Subscribe</button>
                        </div>
                    </form>
                </div>
                <div className=' font-mon not-italic font-medium text-base leading-7 text-white mt-12'>
                    <p className=''>

                    We don’t share your personal information 
                    with anyone or company. Check out our  
                    <span className=' hover:underline hover:transition-all text-[#8B014A] cursor-pointer'><Link to='/privacy-and-cookie-policy'> Privacy Policy </Link></span>for more information.</p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default NewsLetter
