import React from 'react'
import './AboutUS.css'

const MerchantSalesChannel = () => {
  return (
    <div className=' w-full h-full'>
    <div className=' backgroundBlack relative bg-bgsmall bg-[#15191B] p-16'>
        <p className=' font-mon not-italic font-bold sm:text-4xl text-xl text-white'>Merchant Sales Channel</p>
      </div>
      <div className='w-[80%] mx-auto mt-10 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p><span className=' text-[#E600FF]'>
        Jurisdiction:<br/>
United States<br/>
Language:<br/>
English<br/>
Last modified: 12/9/2021 <br/><br/></span>
Merchant Managed Delivery Sales Channel Addendum
This Merchant Managed Delivery Sales Channel Addendum (“Merchant Managed Delivery
Addendum”) applies to Merchant’s use of the Merchant Managed Delivery Sales Channel (as
such term is defined in the General Terms and below) and is hereby incorporated into the
Agreement between My Plug and Merchant, which includes the General Terms currently
available at: <a href='https://myplug.us/'>https://www.myplug.us/</a>, as may be updated from time to time, any Sales Channel
Addenda or Order Form used by Merchant and any other Supplemental Terms. Capitalized
terms not otherwise defined in this Merchant Managed Delivery Addendum will have the
meanings assigned to such terms in the rest of the Agreement and, in the event of a conflict
between the Merchant Managed Delivery Addendum and any of the other terms and conditions
of the Agreement, the Merchant Managed Delivery Addendum will control.

        </p>
      </div>
      <div className='where w-[80%] mx-auto mt-10'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>Additional Defined Terms</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>“Merchant Managed Delivery Staff (MMDS)” means employees, contractors, workers or agents
of Merchant who provide delivery services on a Merchant’s behalf, arranged independently of
My Plug.
<br/>“Merchant Managed Delivery Sales Channel” Merchant may sell Items through the My Plug
Website to Customers who access and request on-demand delivery services provided by
MMDS.
<br/>
“Customer Information” means information about a Customer or that Customer’s Personal Data
made available to Merchant or MMDS in connection with a request for and use of delivery
devices, which may include delivery drop-off location, a Customer’s name or company name, a
Customer’s contact information, a Customer’s signature, and a Customer’s photo, as well as
any other relevant details specific to the items to be delivered.
<br/>“Transportation Method” means a mode of transportation that will be used by the MMDS for the
purpose of providing delivery services in accordance with, and subject to, any other applicable
standards with respect to such Transportation Method.
</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-20'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl
      leading-lineHeight44 text-[#E600FF]'>
        <p>Merchant Provided Delivery Partners.
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Provision of delivery services. As between My Plug and Merchant, Merchant acknowledges and
agrees that: (a) Merchant and MMDS will be solely responsible for determining the most
effective, efficient and safe manner to perform each instance of delivery services; and (b) except
for the My Plug Tools, Merchant will provide all necessary equipment, tools and other materials,
at Merchant's own expense, necessary to perform delivery services. It is recommended that: (i)
Merchant provides detailed instructions for pickup and drop-off (e.g., the location within the
building address to pick-up/drop-off a package, etc.) to MMDS; and (ii) MMDS wait at least ten
(10) minutes for a Customer to appear at the requested drop-off location prior to leaving or
cancelling the delivery services. Merchant will not, and will ensure that all MMDS do not contact
any Customers or use any Customer Information collected in the course of providing the
delivery services for any reason other than for the purposes of fulfilling delivery services for that
Customer’s particular order. Merchant will not retain Customer Information or any of the
Customer’s personal data for longer than it takes to fulfill the delivery services for that
Customer’s particular order<br/>Merchant Relationship with Customers. My Plug and its Affiliates are not responsible or liable for
the actions or inactions of a Customer in relation to any of Merchant's activities, any MMDS or
any Transportation Method. Merchant acknowledges and agrees that each MMDS member
(“MMDS member”) will have the sole responsibility for any obligations or liabilities to Merchant,
Customers or other third parties that arise from or relate to Merchant's or MMDS’s provision of
delivery services. Merchant acknowledges and agrees that Merchant and each MMDS member
is each solely responsible for: (i) any liability arising from or relating to a Customer or any other
third party in connection with the delivery services and (ii) taking such precautions as may be
reasonable and proper, including, without limitation, maintaining insurance in accordance with
applicable laws and conducting thorough background check investigations in connection with
the delivery services; and (b) My Plug or its Affiliates may release the contact and/or insurance
information of Merchant and/or MMDS member to a Customer upon such Customer’s
reasonable request (e.g., in connection with an accident). Merchant will provide evidence of
such precautions upon My Plug’s request.
<br/>Merchant Relationship with MMDS. Merchant will have the sole responsibility for any obligations
or liabilities to MMDS that arise from or relate to Merchant's relationship with MMDS (including
in connection with the provision of delivery services). Merchant acknowledges and agrees that
Merchant exercises sole control over the MMDS and will comply with: (i) all applicable laws
(including tax, gratuity, social security and employment laws) and regulations applicable to
Merchant's relationship with MMDS and (ii) industry best practice in respect of working
conditions and compensation for MMDS, including the distribution of any gratuities. As between
the parties, Merchant acknowledges and agrees that Merchant is at all times responsible and
liable for the acts and omissions of MMDS vis-à-vis Customers, My Plug and its Affiliates, even
where such liability may not be mandated under applicable law.
<br/>Delivery of Alcohol Items. Merchant acknowledges that sale and fulfillment of Alcohol Items (as
defined in the Terms of Use for Alcohol Sales linked herein) sold via the Merchant Managed
Delivery Sales Channel and delivered by MMDS shall be governed by the additional terms and
conditions available here. Merchant is responsible for compliance with applicable laws and
regulations related to such Alcohol Items, including receipt and documentation of valid proof of a
Customer's or MMDS identity and age at the time of delivery, and confirmation that such
Customer or MMDS is not intoxicated at the time of delivery.
</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-20'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>Merchant Additional Obligations for Merchant Provided Services
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Delivery Fee and Tax. Merchant is solely responsible for determining the fee that will be charged
to the Customer for all Items delivered by MMDS pursuant to this Agreement (“Delivery Fee”).
Merchant will provide My Plug with the desired Delivery Fee and My Plug will display this
Delivery Fee on the Platform for all applicable Items. For the sake of clarity, the Delivery Fee will
not include Sales Tax, but Merchant is solely responsible for determining all applicable Sales
Tax and identifying and informing My Plug of the appropriate Sales Tax and other fee amount for
My Plug to charge Merchant's Customers on Merchant's behalf for Delivery Fees charged under
this agreement for Merchant's Items delivered by MMDS. My Plug reserves the right to charge
Merchant's Customers additional delivery fees on Merchant's behalf, including but not limited to,
a small basket fee.<br/>Delivery Area and Delivery Timing. Merchant is responsible for determining the geographic area
in which MMDS will be able to deliver Merchant's Items to Customers (“Merchant Delivery
Area”). In relation to events such as inclement weather, high traffic, or poor driving conditions
(“Adverse Delivery Events”) My Plug may limit the Merchant Delivery Area, at My Plug’s
discretion and without notice to Merchant, to ensure safe and reliable Merchant Managed
delivery services. Merchant acknowledges that My Plug will use the Merchant Delivery Area to
limit the Merchant’s ability to sell Items only to those potential Customers who request delivery
within the Merchant Delivery Area through the MMDS. Merchant is also responsible for
providing My Plug with projected timelines for the preparation of Merchant's Item orders. My
Plug will use Merchant’s preparation timelines and My Plug’s internal projection of delivery time
in determining the estimated time for delivery that will be displayed in Platform for all Merchant's
applicable Items. Merchant acknowledges that My Plug’s systems may require adjustments to
Merchant Delivery Area.<br/>Item Order Support. Merchant is solely responsible for providing all Customer support for Items
sold through the Merchant Managed Delivery Sales Channel. Merchant will provide My Plug
with a customer service phone number that will be displayed to Customers in the My Plug
Website so that Customers may direct their support inquiries to Merchant. Merchant is solely
responsible for resolving all complaints and issues raised for Items delivered through the
Merchant Managed Delivery Sales Channel and will accept and respond to all related customer
service inquiries from Customers.
</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-20'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p> MMDS Transportation Methods.
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>MMDS Requirements. Merchant acknowledges and agrees that all MMDS will, at all times: (a)
hold and maintain (i) a valid applicable license with the appropriate level of certification to
operate the Transportation Method assigned to each MMDS (e.g., a driver’s license if the
Transportation Method is a motor vehicle), and (ii) all licenses, permits, approvals and authority
applicable to Merchant and/or MMDS that are necessary to provide delivery services to third
parties in the territory; (b) provide the delivery services in a professional manner with due skill,
care and diligence; and (c) maintain high standards of professionalism, service and courtesy.
Merchant agrees to undertake background and driving record checks on MMDS before they can
provide delivery services on Merchant's behalf and from time to time thereafter. Merchant
acknowledges and agrees that My Plug reserves the right, at any time in My Plug’s sole
discretion, to restrict Merchant or MMDS from accessing or using the My Plug Platform if
Merchant or such MMDS fails to meet any applicable requirements.<br/>Transportation Method Requirements. Merchant acknowledges and agrees that any
Transportation Method will, at all times, be: (a) properly registered and licensed to operate as a
delivery vehicle in the territory (if the Transportation Method is a vehicle); (b) owned or leased
by Merchant, or otherwise in Merchant's lawful possession; (c) suitable for performing the
delivery services contemplated by this Agreement; and (d) maintained in good operating
condition, consistent with industry safety and maintenance standards for a Transportation
Method of its kind and any additional standards or requirements in the applicable territory, and in
a clean and sanitary condition<br/>Documentation. To the extent required by applicable law, Merchant must retain all copies of any
required licenses, permits, approvals, authority, registrations and certifications prior to
Merchant's and the applicable MMDS provision of any delivery services</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-20'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>Ratings
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Merchant acknowledges and agrees that, after completion of an instance of delivery services,
Merchant and/or Customers may be prompted by My Plug’s technology platform to provide a
rating of such delivery services and MMDS and, optionally, to provide comments or feedback
about such delivery services and MMDS.<br/>My Plug and its affiliates reserve the right to use, share and display the ratings and comments of
Merchant, MMDS, and Customers in any manner in connection with the business of My Plug
and its Affiliates, without attribution to or approval from Merchant or the applicable MMDS.
Merchant acknowledges and agrees that My Plug and its Affiliates are distributors (without any
obligation to verify) and not publishers of the ratings or comments of Merchant or any MMDS,
provided that My Plug and its Affiliates reserve the right to edit or remove comments in the event
that such comments include obscenities or other objectionable content, include an individual’s
name or other personal information, or violate any privacy laws, other applicable laws, or My
Plug’s or its Affiliates’ content policies.

</p>

        </div>
      </div>
      <div className='where w-[80%] mx-auto mt-20'>
      <div className=' text-start font-mon not-italic 
      font-extrabold sm:text-3xl 
      leading-lineHeight44 text-[#E600FF]'>
        <p>Payment
</p>
      </div>
        <div className=' mt-3 text-start thistext font-mon not-italic font-medium text-base leading-7 text-[#626262]'>
        <p>Delivery Services. Notwithstanding anything to the contrary in the General Terms, Merchant will
pay MMDS for their delivery services provided to Merchant at Merchant's sole discretion (but at
all times in compliance with applicable laws and regulations), and Merchant is at all times solely
responsible for providing payment to MMDS, including the distribution of any gratuities.
Merchant acknowledges and agrees that Merchant is required to: (a) complete all tax
registration obligations and calculate and remit all tax liabilities related to the provision of
delivery services and receipt of the My Plug Tools as required by applicable law; and (b) provide
My Plug with all relevant tax information. Merchant further acknowledges and agrees that
Merchant and each MMDS Member is responsible for taxes on their own receipts arising from
the performance of delivery services.

</p>

        </div>
      </div>
    </div>
  )
}

export default MerchantSalesChannel
