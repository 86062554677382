import blog1 from '../resources/tbb.jpg'
import blog2 from '../resources/tbbfoun.jpg'
import blog3 from '../resources/tbbdir.jpg'
import timer from '../resources/timer.png'
import calendar from '../resources/calendar.png'

const BlogObj =
[
    {
        img: blog1,
        title: 'At The Brand Bucks, our mission is to set the gold standard for excellence in brand reviews and consumer engagement...',
        readTime: '8 min read',
        timePosted: 'October 22, 2022',
        timer: timer,
        calendar: calendar,
        job: "CEO",
        to: '/ceo'
        
    },
    {
        img: blog2,
        title: 'As the founder of The Brand Bucks, I am thrilled to share our vision with you—a vision that goes beyond being...',
        readTime: '8 min read',
        timePosted: 'October 22, 2022',
        timer: timer,
        calendar: calendar,
        job: "Founder",
        to: '/founder'
        
    },
    {
        img: blog3,
        title: 'As the Director of our esteemed company, it is my privilege to extend a warm welcome to you, whether you are a seasoned...',
        readTime: '8 min read',
        timePosted: 'October 22, 2022',
        timer: timer,
        calendar: calendar,
        job: "Director",
        to: '/director'
        
    }
]
export default BlogObj