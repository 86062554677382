import React, { useEffect, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import axios from 'axios';
import './Reviewers.css';
import { motion } from 'framer-motion';
// Card data in USD
const cardData = [
  {
    id: 1,
    title: "Silver",
    price: 20, // Price in USD
    label: "Trial",
    features: ["Owning all products", "Product range ($1 to $10)", "Price bounty (after completing all tasks)"],
    style:"single-pricing-silver"
   
  },
  {
    id: 2,
    title: "Gold",
    price: 80, // Price in USD
    label: "Standard",
    features: ["Owning all products", "Product range ($10 to $100)", "Price bounty with every task"],
    style: "single-pricing-gold",
  
    textColor: "text-white",
  },
  {
    id: 3,
    title: "Platinum",
    price: 160, // Price in USD
    label: "Premium",
    features: ["Owning all products", "Product range ($100 to $1500)", "Price bounty with every task"],
    style: "single-pricing-white",
    
  },
];

function Card({ title, price, label, features, style, textColor = "", currencySymbol }) {
  return (
    <div className={`col-lg-4 md:mb-0 mb-3 md:w-1/3 col-sm-4 col-xs-12 wow fadeInUp ${style}`} data-wow-duration="1s" data-wow-delay="0.1s" data-wow-offset="0" id='prc'>
      <div className={`single-pricing ${style}`}>
        <div className="price-head">								
          <h2 className={textColor}>{title}</h2>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <span className="price-label shadow-lg">{label}</span>
        <h1 className={`price ${textColor} mt-7`}>{currencySymbol}{price}</h1>
        <ul>
          {features.map((feature, index) => (
            <li key={index} className={textColor}>{feature}</li>
          ))}
        </ul>
        <Link to="/sign-up" id='start'>Get start</Link>
      </div>
    </div>
  );
}

function Reviewers() {
  const [seeMore, setSeetMore] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('$');
  const [conversionRates, setConversionRates] = useState({});
  const [convertedPrices, setConvertedPrices] = useState(cardData);

  const seeMoreFunction = () => {
    setSeetMore(!seeMore);
  };

  useEffect(() => {
    // Fetch user's country and conversion rates
    const fetchUserCountryAndRates = async () => {
      try {
        // Fetch user's country
        const countryResponse = await axios.get('https://ipapi.co/json/');
        const userCountry = countryResponse.data.country;

        // Fetch conversion rates
        const conversionResponse = await axios.get(`https://api.exchangerate-api.com/v4/latest/USD`);
        const rates = conversionResponse.data.rates;

        // Determine the currency symbol
        const currencySymbols = {
          USD: '$', EUR: '€', GBP: '£', // Add more symbols as needed
        };
        const userCurrency = conversionResponse.data.base;
        const symbol = currencySymbols[userCurrency] || '$';

        setCurrencySymbol(symbol);
        setConversionRates(rates);

        // Convert the prices
        const updatedPrices = cardData.map(card => ({
          ...card,
          price: (card.price * rates[userCurrency]).toFixed(2), // Convert price to user's currency
        }));
        setConvertedPrices(updatedPrices);
      } catch (error) {
        console.error("Error fetching user's country or conversion rates:", error);
      }
    };

    fetchUserCountryAndRates();
  }, []);


  const showitemVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: { opacity: 1, y: 10 },
  };



  const Sliding_Content = () => (
    <>
      <h1 className="text-xl text-start font-bold">Reviewer Incentive Structure</h1>
      <h3 className="text-xl text-start text-[#88014A] font-semibold">1- Silver Package</h3>
      <p className="text-left font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]">
        Reviewers are entitled to a one-time price bounty upon successful completion of all assigned tasks. The bounty amount is contingent upon individual performance and progress.
      </p>
      <h3 className="text-xl text-start text-[#88014A] font-semibold">2- Gold & Platinum Packages</h3>
      <p className="text-left font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]">
        Reviewers will receive a price bounty upon completion of each level, in addition to the rewards for completing all designated tasks. Fulfillment of all requirements is mandatory to qualify for the price bounty at each level.
      </p>
    </>
  );
  return (
    
    <div>
      <section id="pricing" className="pricing-content section-padding">
        <div className='md:p-12 p-3 backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background  '>
          <div className='font-mon not-italic font-bold text-4xl text-white pt-12'>
            <p>Reviewers</p>
          </div>
        </div>
        <div className='md:w-[94%] w-[100%] border rounded-2xl shadow-lg md:p-8 p-2 mx-auto md:mt-8 mt-2'>
          <h1 className='  text-start text-2xl font-bold text-[#8B014A]   '>Become a Reviewer:</h1>
          <p className='text-left font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
            With our unparalleled review platform, brands experience accelerated growth by leveraging the power of authentic feedback. Meanwhile, our reviewers are rewarded handsomely for their valuable contributions to the review process.
          </p>
          <h1 className='text-xl text-start font-bold'>Get Benefits to start your Membership as Reviewers:</h1>
          <h3 className='text-xl text-start text-[#88014A] font-semibold'>1- Product Ownership</h3>
          <p className='text-left font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>Upon completing reviews on at least three distinct social media platforms, reviewers will gain full ownership of the product provided for review. It is essential for reviewers to fulfill all task requirements to qualify for ownership.</p>
          <h3 className='text-xl text-start text-[#88014A] font-semibold'>2- Bonus and Bounty</h3>
          <p className='text-left font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>Reviewers who successfully fulfill all requirements of assigned tasks will receive a specified bonus amount, in addition to retaining ownership of the product. This bonus serves as an acknowledgment of their dedication and effort in completing the tasks <span onClick={seeMoreFunction} className='hover:underline cursor-pointer hover:text-[#0056B3] font-semibold'>{seeMore ?"...see less":"...see more"}</span></p>

          {seeMore&&
            <motion.div
            initial='hidden'
            whileInView='visible'
            variants={showitemVariants}
            transition={{duration: 0.7}}
          className='p-3 w-full mx-auto'
            viewport={{ once: false }}>
           <Sliding_Content/>    
           </motion.div>
          }

          <div className="ml-18">
            <div className="section-title m-8 text-center">
              <h1 className='text-[#8B014A] font-bold text-2xl' >Deposit Plan</h1>
            </div>
            <div className="row text-center gap-4 mx-auto md:flex">
              {convertedPrices.map((card) => (
                <Card key={card.id} {...card} currencySymbol={currencySymbol} />
              ))}
            </div>
          </div>

          <div className='w-full mx-auto text-start mt-10 p-1'>
            <h1 className='text-[#88014A] font-bold text-2xl text-center'>Security Deposit</h1>
            <p className='text-start font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
              At The Brands Bucks, we take pride in our commitment to quality reviews for brands and products. In order to maintain the integrity of our reviewing program, we have implemented a security deposit policy for new reviewers from some regions.
              <br />
              We understand the prevalence of online scams in some regions and recognize the importance of ensuring that our reviewers are genuinely dedicated to providing valuable feedback. Unfortunately, some individuals may seek to exploit our program for personal gain without fulfilling their reviewing responsibilities, leading to significant losses for both our company and the brands we represent.
              <br />
              To address this concern, we have established three distinct reviewer categories: Silver, Gold and Platinum each requiring a specific deposit amount. This deposit serves as a testament to our reviewers' commitment and dedication to the reviewing process.
              <br />
              We firmly believe that when we entrust a product for review, we invest not only resources but also our trust in the reviewer. However, should a reviewer fail to uphold their responsibilities, it results in a loss for our company. Thus, the purpose of the deposit is to mitigate such risks and ensure mutual respect and accountability in our partnership.
              <br />
              We believe that this policy fosters a transparent and mutually beneficial relationship between our reviewers and our company. By upholding these standards, we can continue to deliver authentic and insightful reviews that benefit both consumers and brands alike.
              <br />
              Thank you for considering The Brands Bucks for your reviewing endeavors. We look forward to the opportunity to collaborate with dedicated reviewers who share our passion for excellence.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Reviewers;
