// src/components/CardGrid.js
import React from 'react';
import Card from './Card';
import { ServiceObject } from './ServiceObject';

const WeServe = () => {
  return (
    <div className="container mx-auto p-4">
      <p className='font-mon not-italic font-extrabold text-2xl md:text-4xl'>
    Industries<span className='text-[#8B014A]'> We Serve</span>
  </p>
      <p className=' font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>We provide comprehensive marketing services for a diverse array 
      of industries, ensuring tailored 
      strategies and solutions to meet the unique needs of each sector.</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {ServiceObject.map((card, index) => (
          <Card key={index} title={card.title} points={card.points} />
        ))}
      </div>
    </div>
  );
};

export default WeServe;
