const UsefulLinks = 
[
    
    
    {
        name: 'Terms & Conditions',
        to: "/t-and-cs"
    },
    
    {
        name: 'Privacy Policy',
        to: "/privacy-and-cookie-policy"
    },
    {
        name: 'Refund Policy',
        to: '/return-policy'
    },
    {
        name: 'About Us',
        to: '/about-us'
    },
    {
        name: 'FAQ',
        to: '/faqs'
    },
    
    {
        name: 'Contact Us',
        to: '/contact-us'
    },

]

export default UsefulLinks