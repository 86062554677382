import React from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player/youtube';
import cs from '../../resources/cs.jpg';
import fna from '../../resources/fna.png';
import hr from '../../resources/hr.jpg';
import itts from '../../resources/itts.jpg';
import mnss from '../../resources/mnss.jpeg';
import denm from '../../resources/denm.jpg';
import hbpos from '../../resources/hbpos.jpg';
import { Link } from 'react-router-dom';

const BPOPage = () => {
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const services = [
    {
      img: cs,
      title: 'Customer Support Services',
      description:
        'Deliver exceptional customer experiences with our 24/7 customer support services. Our multilingual support team is trained to handle inquiries, complaints, and provide product information across various channels.',
      points: [
        'Inbound and Outbound Call Center',
        'Technical Support',
        'Help Desk Services',
        'Email and Chat Support',
        'Social Media Management',
      ],
    },
    {
      img: fna,
      title: 'Finance and Accounting Services',
      description:
        'Streamline your financial processes and ensure compliance with our expert finance and accounting services. Our team of qualified accountants can manage your financial operations with precision and accuracy.',
      points: [
        'Accounts Payable and Receivable',
        'Payroll Processing',
        'Tax Preparation and Filing',
        'Financial Reporting and Analysis',
        'Bookkeeping Services',
      ],
    },
    {
      img: hr,
      title: 'Human Resources Outsourcing',
      description:
        'Enhance your HR functions with our comprehensive human resources outsourcing services. We help manage the entire employee lifecycle from recruitment to retirement.',
      points: [
        'Recruitment and Staffing',
        'Employee Onboarding',
        'Payroll and Benefits Administration',
        'Performance Management',
        'HR Compliance and Consulting',
      ],
    },
    {
      img: itts,
      title: 'IT and Technical Support Services',
      description:
        'Keep your IT infrastructure running smoothly with our reliable IT and technical support services. We offer a range of solutions to support your technology needs and ensure operational continuity.',
      points: [
        'IT Helpdesk Support',
        'Network and Infrastructure Management',
        'Cybersecurity Services',
        'Software Development and Maintenance',
        'Data Backup and Recovery',
      ],
    },
    {
      img: denm,
      title: 'Data Entry and Management',
      description:
        'Ensure accuracy and efficiency in your data management with our dedicated data entry services. We handle large volumes of data swiftly and accurately to help you maintain organized records.',
      points: [
        'Data Entry and Processing',
        'Data Cleansing and Validation',
        'Database Management',
        'Document Digitization',
        'Data Analysis and Reporting',
      ],
    },
    {
      img: mnss,
      title: 'Marketing and Sales Support',
      description:
        'Boost your marketing and sales efforts with our expert support services. We provide a range of solutions to enhance your brand presence and drive sales growth.',
      points: [
        'Lead Generation',
        'Market Research',
        'Digital Marketing',
        'Telemarketing',
        'Campaign Management',
      ],
    },
    {
      img: hbpos,
      title: 'Healthcare BPO Services',
      description:
        'Specialized BPO services tailored for the healthcare industry, ensuring compliance and efficiency in managing healthcare operations.',
      points: [
        'Medical Billing and Coding',
        'Claims Processing',
        'Patient Scheduling and Follow-ups',
        'Medical Transcription',
        'Revenue Cycle Management',
      ],
    },
  ];

  const benefits = [
    {
      title: 'Expertise and Experience',
      description:
        'With years of experience in the BPO industry, our team of professionals is equipped with the knowledge and skills to handle diverse business processes efficiently.',
    },
    {
      title: 'Customized Solutions',
      description:
        'We understand that each business is unique. Our services are customizable to meet your specific requirements and objectives.',
    },
    {
      title: 'Cutting-edge Technology',
      description:
        'We leverage the latest technology and software to ensure high-quality service delivery and operational efficiency.',
    },
    {
      title: 'Cost-effective',
      description:
        'Our BPO services are designed to be cost-effective, helping you save on operational costs while maintaining high service standards.',
    },
    {
      title: 'Quality Assurance',
      description:
        'We adhere to strict quality assurance protocols to ensure that all our services meet the highest standards of quality and reliability.',
    },
    {
      title: 'Confidentiality and Security',
      description:
        'We prioritize the confidentiality and security of your data. Our robust security measures ensure that your information is protected at all times.',
    },
  ];

  return (
    <div>
      <div className='relative backgroundBlack bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold md:text-4xl text-2xl text-white pt-12'>
          <p>Business Process Outsourcing (BPO) Services</p>
        </div>
      </div>

      <div className='w-full md:p-10 p:4 text-center'>
        <div className='mx-auto md:p-10 p:0'>
        <motion.div
        
              initial='hidden'
              whileInView='visible'
              variants={itemVariants}
              transition={{ duration: 0.5 }}
              className='pl-4 pr-4 pt-3'
              viewport={{ once: false }}>
          
        
          <div className='text-start font-semibold text-lg'>
            <p className='font-mon pb-2 not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
              Welcome to The Brand Bucks, your trusted partner in Business Process Outsourcing (BPO). We provide comprehensive and customizable solutions designed to help businesses streamline operations, reduce costs, and enhance productivity. Our services cover a broad spectrum of business needs, ensuring that you can focus on your core competencies while we manage the rest.
            </p>
            <div className='right pr-4 w-full'>
      <div className='video-wrapper'>
        <ReactPlayer
          url='https://youtu.be/--4O4nG8NV4?si=xEzhKKXFmsLdOoZC'
          width='100%'
          height={400}
          controls={true}
          className='rounded-3xl react-player shadow-lg'
        />
      </div>
    </div>
          </div>
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial='hidden'
              whileInView='visible'
              variants={itemVariants}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className='pl-4 pr-4 pt-3'
              viewport={{ once: false }}
            >
              <div
                className={`md:flex w-full ${index % 2 === 0 ? '' : 'flex-row-reverse justify-start text-start'} items-start mb-5`}
                style={{ marginRight: index % 2 !== 0 ? '1%' : '0' }}
              >
                <div className={`text-start ${index %2 ===0 ? '': ' ml-5'} md:w-[60%]`}>
                  <h1 className='font-bold text-[#8B014A] md:text-xl text-xl leading-normal'>{service.title}</h1>
                  <p className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>{service.description}</p>
                  <ul className='list-disc pl-5'>
                    {service.points.map((point, i) => (
                      <li key={i} className='font-mon not-italic mt-3 text-base font-medium leading-7 text-[#626262]'>
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='right md:w-[40%]'>
                  <img src={service.img} alt={service.title} className='rounded-md shadow-lg' />
                </div>
              </div>
            </motion.div>
          ))}
    
<Link to='/contact-us'><div className="flex justify-center items-center">
<motion.button
          initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: false }}
              id="btn"
    className="flex justify-center items-center mr-0 whitespace-nowrap px-6 py-3 bg-[#88014A] hover:bg-[#340941] rounded-3xl text-white relative font-semibold font-sans after:-z-20 after:absolute after:h-1 after:w-1 after:bg-[#8B014A] after:left-5 overflow-hidden after:bottom-0 after:translate-y-full after:rounded-md after:hover:scale-[300] after:hover:transition-all after:hover:duration-700 after:transition-all after:duration-700 transition-all duration-700 text-base sm:text-lg md:text-xl">
 
Contact Us for BPO</motion.button></div> </Link>    

          </motion.div>
        </div>
        
      </div>

      <div className='w-full mt-10'>
        <div className='mx-auto w-[90%] text-start p-3 rounded-md shadow-lg'>
          <h2 className='font-mon not-italic font-bold md:text-3xl text-2xl text-[#15191B]'>Why Choose <span className=' text-[#88014A]'>The Brand Bucks?</span></h2>
          <div className='mt-5'>
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial='hidden'
                whileInView='visible'
                variants={itemVariants}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className='pl-4 pr-4 pt-3'
                viewport={{ once: false }}
              >
                <h3 className='font-semibold md:text-lg text-base leading-normal text-[#88014A]'>{benefit.title}</h3>
                <p className='p-1 font-mon text-base'>{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BPOPage;
