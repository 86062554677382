import React, { useState, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { motion } from 'framer-motion';
import vector from '../resources/vector.png';
import './Home.css';
import Registration from './Registration';

const BecomeADriver = () => {
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [age, setAge] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [result, setResult] = useState("");
  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "e947c9ff-2429-4075-b273-a42d76c27695");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Your message has been sent successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length + selectedFiles.length > 3) {
      alert('You can only upload up to 3 files.');
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };


  const handleDateChange = (e) => {
    const dob = new Date(e.target.value);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    setAge(age);
    setDateOfBirth(e.target.value);
  };

  const toggleModal = () => {
    if (formRef.current.checkValidity()) {
      setIsModalOpen(!isModalOpen);
    } else {
      formRef.current.reportValidity();
    }
  };

  return (
    <div className="content-center justify-center w-full">
      <div className='backgroundBlack relative bg-cover bg-[#15191B] bg-newsletter newsletter-background p-12'>
        <div className='font-mon not-italic font-bold text-4xl text-white pt-12'>
          <p>Register Yourself</p>
        </div>
      </div>
      <motion.div 
        className="w-full md:flex" 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}
      >
        <div className="mx-auto border-2 m-5 rounded-lg shadow-xl md:w-[50%] form-input">
          <div className="p-4">
            <form onSubmit={onSubmit} ref={formRef}>
              <div className="md:flex space-x-2 mt-2">
                <div className="md:w-1/2">
                  <div className="relative">
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder=" "
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="first_name"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Full Name
                    </label>
                  </div>
                </div>
                <div className="md:w-1/2">
  <div className="relative flex items-center">
    <label
      htmlFor="date_of_birth"
      className="text-gray-500 mr-4 text-base peer-placeholder-shown:text-gray-500 peer-focus:text-[#8B014A] peer-focus:text-xs transition-all duration-300 ease-in-out"
    >
      Date of Birth
    </label>
    <input
      type="date"
      id="date_of_birth"
      name="date_of_birth"
      placeholder=" "
      required
      value={dateOfBirth}
      onChange={handleDateChange}
      className="peer font-sans md:mt-0 mt-2 md:pr-3 pr-0 text-base bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out flex-grow"
    />
  </div>
</div>

              </div>
              <div className=' mt-6 space-x-2 flex'>
              <div className="relative w-1/2">
                    <input
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      title="Please enter a valid email address"
                      type="email"
                      id="email"
                      name="email"
                      placeholder=" "
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="email"
                      className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Email
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <input
         
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      title="Please enter a valid email address"
                      type="email"
                      id="confirmemail"
                      name="confirmemail"
                      placeholder=" "
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="confirmemail"
                      className="absolute md:mr-0 mr-1 top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Confirm Email
                    </label>
                  </div>
              </div>     
              
              <div className="mt-6 space-x-0 md:space-x-2 flex flex-col md:flex-row">
  <div className="relative w-full sm:w-1/2">
    <input
      pattern="^\+?[1-9]\d{1,14}$"
      title="Please enter a valid phone number (e.g., +1234567890)"
      type="tel"
      id="phonenumber"
      name="phonenumber"
      placeholder=" "
      required
      className="peer font-sans text-base h-12 p-3 sm:h-20 md:h-12 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
    />
    <label
      htmlFor="phonenumber"
      className="absolute left-4 md:pl-0 pr- text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
    >
      Phone Number
    </label>
  </div>
  <div className="relative w-full sm:w-1/2 mt-7 md:mt-0">
    <input
      pattern="^\+?[1-9]\d{1,14}$"
      title="Please enter a valid phone number (e.g., +1234567890)"
      type="tel"
      id="confirmphonenumber"
      name="confirmphonenumber"
      placeholder=" "
      required
      className="peer font-sans h-12 sm:h-20 md:h-12 text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
    />
    <label
      htmlFor="confirmphonenumber"
      className="absolute md:mt-0 mt-1 top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-4 peer-focus:left-2 peer-focus:text-[#8B014A] peer-focus:text-xs peer-focus:scale-110"
    >
      Confirm Phone Number
    </label>
  </div>
</div>

              <div className=' mt-6 space-x-2 flex'>
              <div className="relative w-1/2">
                    <input
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                    title="Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit."
                      type="password"
                      id="password"
                      name="password"
                      placeholder=" "
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="password"
                      className="absolute md:ml-0 ml-1 top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs peer-focus:scale-110"
                    >
                      Password
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <input
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                    title="Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one digit."
                      type="password"
                      id="confirmpassword"
                      name="confirmpassword"
                      placeholder=" "
                      required
                      className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="confirmpassword"
                      className="absolute top-0 left-4 mr-3 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                      Confirm Password
                    </label>
                  </div>
              </div>
       
              <div className="md:w-full mt-6">
                <div className="relative">
                  <textarea
                    type="text"
                    id="address"
                    name="address"
                    placeholder=" "
                    required
                    className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                  />
                  <label
                    htmlFor="address"
                    className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-1 peer-focus:text-[#8B014A] peer-focus:text-xs"
                  >
                    Address (As per Legal Documents)
                  </label>
                </div>
              </div>
              {age !== null && age < 18 && (
                <motion.div 
                  className="w-full mt-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="md:flex space-x-0 md:space-x-2">
                    <div className="md:w-1/2">
                      <div className="relative">
                        <input
                          type="text"
                          id="guardian_name"
                          name="guardian_name"
                          placeholder=" "
                          required
                          className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                        />
                        <label
                          htmlFor="guardian_name"
                          className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
                        >
                          Guardian's Name
                        </label>
                      </div>
                    </div>
                    <div className="md:w-1/2 w-full relative w-full sm:w-1/2 md:mt-0 mt-6">
                      <div className="relative">
                        <select 
                          id="guardian_relationship"
                          name="guardian_relationship"
                          className="peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                        >
                          <option defaultValue>Select Guardian Relationship</option>
                          <option>Parents</option>
                          <option>Grandparents</option>
                          <option>Siblings</option>
                          <option>Aunt/Uncle</option>
                          <option>Other Relatives</option>
                        </select>
                        <label
                          htmlFor="guardian_relationship"
                          className="absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
                        >
                        
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className=' mt-6 space-x-2 flex'>
              <div className="relative w-1/2">
                    <input
                      pattern="^\+?[1-9]\d{1,14}$"
                    title="Please enter a valid phone number (e.g., +1234567890)"
                    type="tel"
  id="Guardianphonenumber"
  name="Guardianphonenumber"
  placeholder=" "
  required
                      className="h-20 sm:h-20 md:h-12 peer font-sans text-base w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
                    />
                    <label
                      htmlFor="Guardianphonenumber"
                      className="md:mt-0 mt-3 mr-2 absolute top-0 left-4 text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-4 peer-focus:text-[#8B014A] peer-focus:text-xs"
                    >
                     Guardian Phone Number
                    </label>
                  </div>
             
              </div> */}
              <div className="relative w-full sm:w-1/2 mt-6">
    <input
      pattern="^\+?[1-9]\d{1,14}$"
      title="Please enter a valid phone number (e.g., +1234567890)"
      type="tel"
      id="Guardianphonenumber"
      name="Guardianphonenumber"
      placeholder=" "
      required
      className="peer font-sans text-base h-12 p-3 sm:h-20 md:h-12 w-full bg-gray-100 border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-0 focus:border-[#8B014A] transition duration-300 ease-in-out"
    />
    <label
      htmlFor="Guardianphonenumber"
      className="absolute left-4 md:pl-0 pr- text-gray-500 transform -translate-y-1/2 transition-all duration-300 ease-in-out peer-placeholder-shown:top-6 peer-placeholder-shown:left-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:left-0 peer-focus:text-[#8B014A] peer-focus:text-xs"
    >
    Guardian Phone Number
    </label>
  </div>
                </motion.div>
              )}
              <div className="mt-6">
      <div className="text-start font-sans text-base text-gray-700 mb-2">
        <p>
          Upload any valid & legal documents (Passport, NIC, Driving Licence){' '}
          <span className="text-red-500">*</span>
        </p>
        <p className="text-sm text-gray-500">
          Drop your file here or click here to upload. You can upload up to 3
          files.
        </p>
      </div>
      <div className="w-full content-center m-auto mt-2">
        <label className="cursor-pointer p-5 content-center justify-center text-center border-4 rounded-lg border-dashed block">
          <div>
            <img className="m-auto" src={vector} alt="Upload" />
          </div>
          <div className="text-gray-800 text-sm mt-3">
            <p className="text-[#8B014A]">Drop your file here or click here to upload</p>
            <p className="text-gray-500">PNG, JPG, PDF, DOC, DOCX, TXT up to 10MB</p>
          </div>
          <input
            type="file"
            multiple
            accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .txt"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
      </div>

      <div className="mt-4">
        {/* <h4>Selected Files:</h4> */}
        <ul>
          {selectedFiles.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>
    </div>
              <button
                type="submit"
                // onClick={toggleModal}
                className="md:w-[15%] w-[30%] text-white bg-[#88014A] hover:bg-[#340941] rounded-3xl py-2 px-4 mt-4 transition duration-300 ease-in-out"
              >
                {age !== null && age < 18 ? 'Submit for Review' : 'Submit'}
              </button>


            </form>
            <span>{result}</span>
          </div>
        </div>
      </motion.div>
      {isModalOpen && (
        <Registration isOpen={isModalOpen} onClose={toggleModal} />
      )}
    </div>
  );
};

export default BecomeADriver;
