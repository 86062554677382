import reviews from '../resources/reviews.jpg'
import harness from '../resources/harness.jpg'
import logic from '../resources/logic.png'
import excellence from '../resources/excellence.png'
import investment from '../resources/investment.jpg'
const BNPObject =
[
    {
        imgs: reviews,
        title: "Unlocking Success with Review Marketing: The Brand Bucks Way",
        description: "In the dynamic landscape of modern business, standing out amidst the cacophony of brands clamoring for consumer attention is a challenge. At The Brand Bucks, we recognize the potency of Review Marketing as the pinnacle of contemporary marketing strategies. Through our innovative approach, we empower our clients to introduce their brands and products to the market with unparalleled efficacy."

    },
    {
        imgs: harness,
        title: "Harnessing the Power of Review Marketing",
        description: "Review Marketing stands as the most formidable weapon in the marketer's arsenal today. With thousands of reviewers spanning the globe within our extensive network, we possess the unparalleled capability to propel brands and products into the limelight. The essence of Review Marketing lies in its authenticity and relatability. Unlike traditional advertising avenues, where consumers are inundated with paid promotions, Review Marketing leverages the trust and credibility inherent in genuine, user-generated content."
        
    },
    {
        imgs: logic,
        title: "The Logic Behind Review Marketing",
        description: "Consider the typical consumer's response to a traditional advertisement—a fleeting acknowledgment, perhaps, but ultimately met with skepticism. In contrast, when presented with a review from an ordinary individual on a social media platform, a profound connection is forged. This connection is rooted in the shared experiences and perspectives of everyday consumers, fostering a level of trust and resonance that traditional advertising struggles to emulate."
        
    },
    {
        imgs: excellence,
        title: "Targeted Excellence: The Brand Bucks Advantage",
        description: "Our approach to Review Marketing extends beyond mere promotion—it's about precision. By leveraging targeted demographics and geographical regions, we ensure that our clients' brands resonate with their intended audience on a profound level. From garnering offers of franchisees and wholesalers to establishing a global presence, our strategy is tailored to maximize impact and drive unprecedented success."
        
    },
    {
        imgs: investment,
        title: "Investment in Trust, Reward in Results",
        description: "Partnering with The Brand Bucks requires a simple yet invaluable investment: trust. Entrust us with your brand or product, and we pledge to deliver results that exceed expectations. Our success is intertwined with yours, as we channel the rewards of our collaboration to incentivize and reward our dedicated reviewers, fostering a symbiotic ecosystem of success."
        
    },
    {
        title: "",
        description: "In conclusion, the era of Review Marketing has dawned, and The Brand Bucks stands at the vanguard of this revolution. Through authenticity, relatability, and targeted precision, we unlock unparalleled success for our clients, one review at a time. Join us in redefining the paradigm of marketing and embrace the limitless potential of Review Marketing with The Brand Bucks."
        
    }
]

export default BNPObject